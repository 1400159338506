import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  apiGetListTimeslotByDate,
  apiGetSeatList,
  apiGetDropOffPoint,
  apiGetPickUpPoint,
  apiFindCoachOnTimeslot,
} from "../../api/services";

const initialState = {
  currentDate: 0,
  currentTimeslot: {
    id: "",
  },
  listTimeslot: [],
  listTicket: [],
  pickUpPoint: [],
  dropOffPoint: [],
  coachOnTimeslot: {},
};

export const requestLoadTimeslotByDate = createAsyncThunk(
  "ticket/requestLoadTimeslotByDate",
  async (props) => {
    const response = await apiGetListTimeslotByDate(props);
    return response.data.data;
  }
);

export const requestLoadTicket = createAsyncThunk(
  "ticket/requestLoadTicket",
  async (props) => {
    const res = await apiGetSeatList(props);
    return res.data.data;
  }
);

export const requestLoadPickUpPoint = createAsyncThunk(
  "ticket/requestLoadPickUpPoint",
  async (props) => {
    const res = await apiGetPickUpPoint(props);
    const listProvince = res.data.data;
    const listPickUpPoint = listProvince.map((province) => {
      return province.districtList.map((district) => ({
        value: district.districtId,
        label: district.district,
      }));
    });
    let optionsPoint = [];
    listPickUpPoint.forEach((province) => optionsPoint.push(...province));
    return optionsPoint;
  }
);

export const requestLoadDropOffPoint = createAsyncThunk(
  "ticket/requestLoadDropOffPoint",
  async (props) => {
    const res = await apiGetDropOffPoint(props);
    const listProvince = res.data.data;
    const listDropOffPoint = listProvince.map((province) => {
      return province.districtList.map((district) => ({
        value: district.districtId,
        label: district.district,
      }));
    });
    let optionsPoint = [];
    listDropOffPoint.forEach((province) => optionsPoint.push(...province));
    return optionsPoint;
  }
);

export const requestFindCoachOnTimeslot = createAsyncThunk(
  "ticket/requestFindCoachOnTimeslot",
  async (props) => {
    const res = await apiFindCoachOnTimeslot(props);
    return res.data.data;
  }
);

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    chooseDate: (state, action) => {
      state.currentDate = action.payload;
    },
    chooseTimeslot: (state, action) => {
      state.currentTimeslot = action.payload;
    },
    setListTicket: (state, action) => {
      state.listTicket = action.payload;
    },
    resetData: (state, action) => {
      state.listTicket = [];
      state.listTimeslot = [];
    }
    // updateLockSeat: (state, action) => {
    //     state.lockSeat = [];
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestLoadTimeslotByDate.fulfilled, (state, action) => {
        state.listTimeslot = action.payload;
      })
      .addCase(requestLoadTicket.fulfilled, (state, action) => {
        state.listTicket = action.payload;
      })
      .addCase(requestLoadTicket.rejected, (state, action) => {
        state.listTicket = [];
      })
      .addCase(requestLoadPickUpPoint.fulfilled, (state, action) => {
        state.pickUpPoint = action.payload;
      })
      .addCase(requestLoadDropOffPoint.fulfilled, (state, action) => {
        state.dropOffPoint = action.payload;
      })
      .addCase(requestFindCoachOnTimeslot.fulfilled, (state, action) => {
        state.coachOnTimeslot = action.payload;
      });
  },
});
export const ticketState = (state) => state.ticketState;

export const { chooseTimeslot, setListTicket, chooseDate, resetData } =
  ticketSlice.actions;
export default ticketSlice.reducer;
