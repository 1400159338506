import { apiUpdatePolicy, apiDelPolicy, apiCreatePolicy } from "../../../../api/services";
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import {
  requestCreatePolicy,
  requestLoadPolicy,
} from "../../../../redux/slices/companySlice";
import { Editor } from "@tinymce/tinymce-react";
import { Typography, Button } from "antd";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";

const { Title } = Typography;

const CreatePolicy = (props) => {
  const { type, policy } = props;
  const editorRef = useRef();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const onCreatePolicy = async () => {
    const data = {
      type,
      content: editorRef.current.getContent(),
      coachCompanyId: companyId,
    };
    const res = await apiCreatePolicy(data)
    if (!res.data.error) {
      enqueueSnackbar("Tạo chính sách thành công", { variant: "success" });
      dispatch(requestLoadPolicy(companyId));
    }
  };

  const onUpdatePolicy = async () => {
    const data = {
      id: policy.id,
      content: editorRef.current.getContent(),
    };
    const res = await apiUpdatePolicy(data);
    if (!res.data.error) {
      enqueueSnackbar("Cập nhật chính sách thành công", { variant: "success" });
      dispatch(requestLoadPolicy(companyId));
    }
  };

  const onDeletePolicy = async () => {
    const res = await apiDelPolicy({id: policy.id});
    if (!res.data.error) {
      enqueueSnackbar("Xóa chính sách thành công", { variant: "success" });
      dispatch(requestLoadPolicy(companyId));
    }
  }

  if (!type) return;

  return (
    <div className="bg-white rounded-lg w-full items-center py-4 px-8 mt-4">
      <Title level={4}>Nội dung</Title>
      <Editor
        initialValue={policy?.content ?? ""}
        apiKey="eoc5f4n17mfwrussb0rmrugnfx1uisfay4fsh3v1p8pdyn6v"
        onInit={(event, editor) => (editorRef.current = editor)}
      />
      <div className="space-x-2">
        <Button
          className="mt-4"
          onClick={policy?.id ? onUpdatePolicy : onCreatePolicy}
        >
          Lưu
        </Button>
        {
            policy?.id && (
                <Button className="del-btn" onClick={onDeletePolicy}>Xóa</Button>
            )
        }
      </div>
    </div>
  );
};

export default CreatePolicy;
