import { Row, Select, Typography, Form, TimePicker, Button, Input } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { useAppSelector } from "../../../../../redux/hook";
import { useEffect, useState } from "react";
import {
  convertSecondsToDayjs,
  regexNumber,
} from "../../../../../utils/convertTime";
import "./style.css";
import { TeamOutlined, UserOutlined } from "../../../../../assets/svgs";

const Section = ({
  section,
  index,
  listSection,
  setListSection,
  listTimeslot,
  setListTimeslot,
  timeslotIndex,
  currentCoach,
  isDisabled,
  setIsCreate,
}) => {
  const [time, setTime] = useState(0);
  const [form] = Form.useForm();
  const listPoint = useAppSelector((state) => state.companyState.listPoint);
  const currenteRouteTemplate = useAppSelector(
    (state) => state.routeState.currentRouteTemplate
  );
  const optionsListPoint = listPoint.map((point) => ({
    value: point.locationId,
    label: point.address,
  }));

  useEffect(() => {
    if (currentCoach == 0) return;
    const tmp = section.price
      ? section.price
      : currenteRouteTemplate[index + 1]?.priceMap[currentCoach];
    const secondPriceTmp = section.secondPrice
      ? section.secondPrice
      : currenteRouteTemplate[index + 1]?.secondPriceMap[currentCoach];
    form.setFieldValue("price", regexNumber(tmp));
    form.setFieldValue("secondPrice", regexNumber(secondPriceTmp));
  }, [currentCoach, listTimeslot]);

  useEffect(() => {
    form.resetFields();
    const priceTmp = section.price
      ? section.price
      : section.priceMap
      ? section.priceMap[currentCoach]
      : 0;
    const secondPriceTmp = section.secondPrice
      ? section.secondPrice
      : section.secondPriceMap
      ? section.secondPriceMap[currentCoach]
      : 0;
    form.setFieldsValue({
      price: regexNumber(priceTmp),
      secondPrice: regexNumber(secondPriceTmp),
      ...section,
    });

    if (section.departureTime) {
      form.setFieldValue(
        "departureTime",
        convertSecondsToDayjs(section.departureTime)
      );
      setTime(section.departureTime);
    } else {
      form.setFieldValue("departureTime", convertSecondsToDayjs(null));
    }
  }, [section, listSection]);

  const handleChooseTime = (e) => {
    const tmp = (e.$H * 3600 + e.$m * 60) * 1000;
    setTime(tmp);
    const sectionInstance = {
      ...listSection[index],
      departureTime: tmp,
    };
    setListSection([
      ...listSection.slice(0, index),
      sectionInstance,
      ...listSection.slice(index + 1),
    ]);
  };
  return (
    <Form
      form={form}
      onValuesChange={() => {
        setIsCreate(true);
        listSection[index] = { ...form.getFieldsValue(), departureTime: time };
        listTimeslot[timeslotIndex].sectionList = listSection;
      }}
    >
      <Row className="space-x-1 grid grid-cols-12">
        <Typography.Title level={5} className="col-span-1">
          {index + 1
            ? index < 9
              ? `Chặng 0${index + 1}`
              : `Chặng ${index + 1}`
            : null}
        </Typography.Title>
        <Form.Item name="departureTime" className="col-span-1">
          <TimePicker
            showNow={false}
            needConfirm={false}
            format="HH:mm"
            placeholder="Giờ"
            onChange={(e) => handleChooseTime(e)}
            allowClear={false}
            disabled={isDisabled}
          />
        </Form.Item>
        <Form.Item name="pickUpPointIdList" className={"col-span-3"}>
          <Select
            className="w-full"
            mode="multiple"
            maxTagCount={1}
            disabled={isDisabled}
          >
            {optionsListPoint.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="dropOffPointIdList" className={"col-span-3"}>
          <Select
            maxTagCount={1}
            options={optionsListPoint}
            className="w-full"
            mode="multiple"
            disabled={isDisabled}
          ></Select>
        </Form.Item>
        <div className="col-span-2">
          <Form.Item name="price" style={{ margin: "0px" }}>
            <Input
              suffix="VND"
              className="w-full map-input"
              disabled={isDisabled}
              prefix={<UserOutlined />}
            />
          </Form.Item>
          {currentCoach < 15 && currentCoach > 9 && (
            <Form.Item name="secondPrice">
              <Input
                suffix="VND"
                className="w-full map-input"
                disabled={isDisabled}
                prefix={<TeamOutlined />}
              />
            </Form.Item>
          )}
        </div>

        {!isDisabled && (
          <Button
            className="del-btn"
            onClick={() => {
              setListSection([
                ...listSection.slice(0, index),
                ...listSection.slice(index + 1),
              ]);
              setListTimeslot([
                ...listTimeslot.slice(0, timeslotIndex),
                {
                  ...listTimeslot[timeslotIndex],
                  sectionList: [
                    ...listTimeslot[timeslotIndex].sectionList.slice(0, index),
                    ...listTimeslot[timeslotIndex].sectionList.slice(index + 1),
                  ],
                },
                ...listTimeslot.slice(timeslotIndex + 1),
              ]);
            }}
            icon={<DeleteFilled />}
          />
        )}
      </Row>
    </Form>
  );
};

export default Section;
