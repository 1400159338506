import { Steering } from "../../../../../assets/svgs";
import ModalDifferentSeat from "./ModalDifferentSeat";
import { useState } from "react";

export const SeatInFirst = (props) => {
  const {
    num,
    first,
    differentFirst,
    setFirst,
    index,
    isPrice,
    applyDateFirst,
    seatConfig,
    seatList,
    setSeatList
  } = props;
  const [modalShow, setModalShow] = useState(false);

  const render = () => {
    switch (num) {
      case "0":
        return (
          <div className="h-fit flex flex-col items-center">
            <Steering />
          </div>
        );
      case null:
        return <div className="w-12 h-8 bg-transparent"></div>;
      case "WC":
        return (
          <div className="flex flex-col items-center">
            <input
              value={"WC"}
              disabled
              className={`${
                isPrice ? "h-12" : "h-8"
              } w-12 border-2 border-green-700 rounded-lg text-center`}
            />
          </div>
        );
      case num:
        if (seatConfig)
          return (
            <div className="flex flex-col items-center">
              <div
                className={`w-12 h-8 border-2 border-green-700 rounded-md text-center cursor-pointer ${seatList.includes(num) ? "bg-green-700 text-white" : "bg-white"}`}
                onClick={() => seatList.includes(num) ? setSeatList(seatList.filter(item => item !=num)) : setSeatList((prev) => [...prev, num])}
              >
                {num}
              </div>
            </div>
          );
        return (
          <div className="flex flex-col items-center">
            <input
              maxLength={3}
              defaultValue={num}
              onChange={(e) => {
                const upper = e.target.value.toUpperCase();
                first[index] = upper;
                e.target.value = upper;
              }}
              className="w-12 h-8 border-2 border-green-700 text-center"
              style={{
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px",
                borderBottomRightRadius: isPrice ? "0px" : "6px",
                borderBottomLeftRadius: isPrice ? "0px" : "6px",
              }}
              disabled={isPrice || seatConfig}
            />
            {isPrice && (
              <a
                onClick={() => setModalShow(true)}
                className={`${
                  differentFirst[index] ? "bg-red-600" : "bg-green-700"
                } w-12 h-4 cursor-pointer border-t-0 border-2 border-green-700 text-xs text-white text-center`}
                style={{
                  borderBottomRightRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
              >
                +/-
              </a>
            )}

            <ModalDifferentSeat
              name={num}
              differentFirst={differentFirst}
              applyDateFirst={applyDateFirst}
              index={index}
              modalShow={modalShow}
              setModalShow={setModalShow}
            />
          </div>
        );
    }
  };

  return render();
};

export const SeatInSecond = (props) => {
  const {
    num,
    second,
    differentSecond,
    setSecond,
    index,
    isPrice,
    applyDateSecond,
    seatConfig,
    seatList,
    setSeatList
  } = props;
  const [modalShow, setModalShow] = useState(false);

  const render = () => {
    switch (num) {
      case null:
        return <div className="w-12 h-8 bg-transparent"></div>;
      case "WC":
        return (
          <div className="flex flex-col items-center">
            <input
              value={"WC"}
              disabled
              className={`${
                isPrice ? "h-12" : "h-8"
              } w-12 border-2 border-green-700 rounded-lg text-center`}
            />
          </div>
        );
      case num:
        if (seatConfig)
          return (
            <div className="flex flex-col items-center">
              <div
                className={`w-12 h-8 border-2 border-green-700 rounded-md text-center cursor-pointer ${seatList.includes(num) ? "bg-green-700 text-white" : "bg-white"}`}
                onClick={() => seatList.includes(num) ? setSeatList(seatList.filter(item => item !=num)) : setSeatList((prev) => [...prev, num])}
              >
                {num}
              </div>
            </div>
          );
        return (
          <div className="flex flex-col items-center">
            <input
              maxLength={3}
              defaultValue={num}
              onChange={(e) => {
                const upper = e.target.value.toUpperCase();
                second[index] = upper;
                e.target.value = upper;
              }}
              className="w-12 h-8 border-2 border-green-700 text-center"
              style={{
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px",
                borderBottomRightRadius: isPrice ? "0px" : "6px",
                borderBottomLeftRadius: isPrice ? "0px" : "6px",
              }}
              disabled={isPrice || seatConfig}
            />
            {isPrice && (
              <a
                onClick={() => setModalShow(true)}
                className={`${
                  differentSecond[index] ? "bg-red-600" : "bg-green-700"
                } w-12 h-4 cursor-pointer border-t-0 border-2 border-green-700 text-xs text-white text-center`}
                style={{
                  borderBottomRightRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
              >
                +/-
              </a>
            )}

            <ModalDifferentSeat
              name={num}
              differentFirst={differentSecond}
              applyDateFirst={applyDateSecond}
              index={index}
              modalShow={modalShow}
              setModalShow={setModalShow}
            />
          </div>
        );
    }
  };

  return render();
};
