import TicketForm from "./TicketForm";
import { Modal } from "antd";
import { useState } from "react";
import "./style.css";
import {
  HistoryTicketIcon,
  UpdateTicketIcon,
} from "../../../../../assets/svgs";
import { useAppSelector } from "../../../../../redux/hook";
import { regexNumber } from "../../../../../utils/convertTime";

const ModalTicketSeller = (props) => {
  const { modalShow, setModalShow, seatName, orderId } = props;
  const [state, setState] = useState(0);

  const currentTimeslot = useAppSelector(
    (state) => state.ticketState.currentTimeslot
  );
  const coachOnTimeslot = useAppSelector(
    (state) => state.ticketState.coachOnTimeslot
  );
  return (
    <div>
      <Modal
        width="800px"
        centered
        footer={null}
        open={modalShow}
        onCancel={() => setModalShow(false)}
        className="ticket-seller-modal"
      >
        <div className="flex flex-row bg-white w-fit h-12 items-center">
          <div
            className={`h-12 px-4 py-1 flex flex-col ${
              state == 1 ? "bg-[#80B155]" : ""
            } cursor-pointer`}
            onClick={() => setState(0)}
          >
            <UpdateTicketIcon />
            <p>Cập nhật</p>
          </div>
          <div
            className={`h-12 px-4 py-1 flex flex-col ${
              state == 0 ? "bg-[#80B155]" : ""
            } cursor-pointer`}
            onClick={() => setState(1)}
          >
            <HistoryTicketIcon />
            <p>Lịch sử</p>
          </div>
        </div>
        <div className="px-12 py-2 bg-white h-[80vh] max-h-fit">
          {state ? (
            <div className="h-[300px]">Lịch sử đặt vé</div>
          ) : (
            <div>
              <div className="flex flex-col">
                <div className="flex flex-row space-x-16">
                  <p>
                    Giá vé:{" "}
                    {regexNumber(
                      coachOnTimeslot.price ?? currentTimeslot.price
                    )}
                    đ
                  </p>
                  <p className="text-red-500">
                    Tổng:{" "}
                    {regexNumber(
                      coachOnTimeslot.price ?? currentTimeslot.price
                    )}
                    đ
                  </p>
                </div>
                <div className="flex flex-row space-x-16">
                  <p>SL: 1</p>
                  <p>Số ghế: {seatName}</p>
                </div>
              </div>
              <TicketForm
                setModalShow={setModalShow}
                seatName={seatName}
                orderId={orderId}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ModalTicketSeller;
