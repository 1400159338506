import { useEffect, useState } from "react";
import { COACH_TYPE } from "../../../../../constant";
import { Button } from "antd";
import { SeatInFirst, SeatInSecond } from "./Seat";
import { useAppSelector } from "../../../../../redux/hook";
import {
  apiCreateSeatDiagram,
  apiCreateSeatPriceDiagram,
  apiGetSeatDiagram,
  apiUpdateSeatDiagram,
  apiGetSeatPriceDiagram,
  apiUpdateSeatPriceDiagram,
} from "../../../../../api/services";
import { useSnackbar } from "notistack";
import { deleteDot } from "../../../../../utils/convertTime";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CoachType = ({
  coachType,
  isPrice,
  setFatherModalShow,
  routeId,
  seatConfig,
  seatList,
  setSeatList,
}) => {
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const [diagram, setDiagram] = useState();
  const [priceDiagram, setPriceDiagram] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [differentFirst, setDifferentFirst] = useState([]);
  const [differentSecond, setDifferentSecond] = useState([]);
  const [applyDateFirst, setApplyDateFirst] = useState([]);
  const [applyDateSecond, setApplyDateSecond] = useState([]);
  const [floorList, setFloorList] = useState([]);

  const [spr, setSpr] = useState(0);

  useEffect(() => {
    setFirst([]);
    setSecond([]);
    handleLoadData();
  }, [coachType]);

  const handleLoadData = async () => {
    if (isPrice) {
      const seatPriceDiagram = await apiGetSeatPriceDiagram({
        coachRouteId: routeId,
        coachTypeId: coachType,
      });
      if (!seatPriceDiagram.data.error) {
        const data = seatPriceDiagram.data.data;
        setPriceDiagram(data);
        const firstFloor = data.floorList[0];
        setDifferentFirst(firstFloor?.map((seat) => seat.difference ?? null));
        setApplyDateFirst(firstFloor?.map((seat) => seat.applyDates ?? null));
        const secondFloor = data.floorList[1];
        setDifferentSecond(secondFloor?.map((seat) => seat.difference ?? null));
        setApplyDateSecond(secondFloor?.map((seat) => seat.applyDates ?? null));
      } else {
        setPriceDiagram();
        setDifferentFirst([]);
        setApplyDateFirst([]);
        setDifferentSecond([]);
        setApplyDateSecond([]);
      }
      // console.log(seat)
    }
    if (!coachType) return;
    const props = {
      companyId: companyId,
      coachTypeId: coachType,
    };
    const res = await apiGetSeatDiagram(props);
    if (!res.data.error) {
      const data = res.data.data;
      setDiagram(data);
      const tmpFirst = data.floorList[0].length
        ? data.floorList[0].map((seat) => seat.name ?? null)
        : COACH_TYPE[coachType].first ?? [];
      const tmpSecond = data.floorList[1].length
        ? data.floorList[1].map((seat) => seat.name ?? null)
        : COACH_TYPE[coachType].second ?? [];
      setFirst(tmpFirst);
      setSecond(tmpSecond);
    } else {
      setDiagram();
      setFirst(COACH_TYPE[coachType].first ?? []);
      setSecond(COACH_TYPE[coachType].second ?? []);
    }
    setSpr(COACH_TYPE[coachType].spr ?? 3);
  };

  const handleCreateSeatDifference = async () => {
    const firstFloor = first.map((seat, index) => ({
      name: seat,
      difference: parseInt(deleteDot(differentFirst[index])),
      applyDates: applyDateFirst[index],
    }));
    const secondFloor = second.map((seat, index) => ({
      seatName: seat,
      difference: parseInt(deleteDot(differentSecond[index])),
      applyDates: applyDateSecond[index],
    }));
    setFloorList([firstFloor, secondFloor]);
    const data = {
      coachTypeId: coachType,
      routeId,
      floorList: [firstFloor, secondFloor],
    };
    const res = await apiCreateSeatPriceDiagram(data);
    if (!res.data.error) {
      enqueueSnackbar("Tạo giá phụ thành công", { variant: "success" });
      setFatherModalShow(false);
    } else {
      enqueueSnackbar("Tạo giá phụ thất bại", { variant: "error" });
    }
  };

  const handleUpdateSeatDifference = async () => {
    const firstFloor = first.map((seat, index) => ({
      name: seat,
      difference: parseInt(deleteDot(differentFirst[index])),
      applyDates: applyDateFirst[index],
    }));
    const secondFloor = second.map((seat, index) => ({
      seatName: seat,
      difference: parseInt(deleteDot(differentSecond[index])),
      applyDates: applyDateSecond[index],
    }));
    setFloorList([firstFloor, secondFloor]);
    const data = {
      id: priceDiagram.id,
      floorList: [firstFloor, secondFloor],
    };
    const res = await apiUpdateSeatPriceDiagram(data);
    if (!res.data.error) {
      enqueueSnackbar("Cập nhật giá phụ thành công", { variant: "success" });
      setFatherModalShow(false);
    } else {
      enqueueSnackbar("Tạo giá phụ thất bại", { variant: "error" });
    }
  };

  const handleCreateSeatDiagram = async () => {
    const firstFloor = first.map((seat, index) => ({
      name: seat,
    }));
    const secondFloor = second.map((seat, index) => ({
      name: seat,
    }));
    const data = {
      coachTypeId: coachType,
      companyId: companyId,
      floorList: [firstFloor, secondFloor],
    };
    const res = await apiCreateSeatDiagram(data);
    if (!res.data.error) {
      enqueueSnackbar("Tạo sơ đồ ghế thành công", { variant: "success" });
    }
  };

  const handleUpdateSeatDiagram = async () => {
    const firstFloor = first.map((seat, index) => ({
      name: seat,
    }));
    const secondFloor = second.map((seat, index) => ({
      name: seat,
    }));
    const data = {
      coachTypeId: coachType,
      floorList: [firstFloor, secondFloor],
      id: diagram.id,
    };
    const res = await apiUpdateSeatDiagram(data);
    if (!res.data.error) {
      enqueueSnackbar("Cập nhật sơ đồ ghế thành công", { variant: "success" });
    }
  };

  if (seatConfig && !diagram)
    return <p>Chưa có sơ đồ ghế, vui lòng tạo sơ đồ ghế</p>;

  return (
    <div className="min-h-fit">
      {coachType ? (
        <div className="flex flex-col w-full h-full min-h-fit space-y-8 min-w-[600px]">
          <div className="grid grid-cols-2">
            <div className="col-span-1 w-full min-h-fit flex flex-col items-center">
              {!!second.length && <p>Tầng dưới</p>}
              <div
                className={`grid grid-cols-${spr} gap-2 mt-4 ${
                  spr == 3 ? "w-48" : "w-64"
                }`}
              >
                {first.map((seat, index) => (
                  <SeatInFirst
                    num={seat}
                    first={first}
                    differentFirst={differentFirst}
                    applyDateFirst={applyDateFirst}
                    setFirst={setFirst}
                    index={index}
                    key={index}
                    isPrice={isPrice}
                    seatConfig={seatConfig}
                    seatList={seatList}
                    setSeatList={setSeatList}
                  />
                ))}
              </div>
            </div>
            <div className="col-span-1 w-full flex flex-col items-center">
              {!!second.length && <p>Tầng trên</p>}
              <div
                className={`col-span-1 grid grid-cols-${spr} gap-2 mt-4 ${
                  spr == 3 ? "w-48" : "w-64"
                }`}
              >
                {second &&
                  second.map((seat, index) => (
                    <SeatInSecond
                      num={seat}
                      second={second}
                      differentSecond={differentSecond}
                      applyDateSecond={applyDateSecond}
                      setSecond={setSecond}
                      index={index}
                      key={index}
                      isPrice={isPrice}
                      seatConfig={seatConfig}
                      seatList={seatList}
                      setSeatList={setSeatList}
                    />
                  ))}
              </div>
            </div>
          </div>
          {isPrice ? (
            <Button
              onClick={() => {
                priceDiagram
                  ? handleUpdateSeatDifference()
                  : handleCreateSeatDifference();
                // setFatherModalShow(false);
              }}
            >
              Xác nhận
            </Button>
          ) : (
            <Button
              style={{
                display: seatConfig ? "none" : "",
              }}
              className="w-1/6"
              onClick={() =>
                diagram ? handleUpdateSeatDiagram() : handleCreateSeatDiagram()
              }
            >
              Xác nhận
            </Button>
          )}
        </div>
      ) : (
        <p className="flex self-center justify-self-center italic">
          Chọn loại xe muốn đánh số
        </p>
      )}
    </div>
  );
};

export default CoachType;
