import { useAppSelector } from "../../../../../redux/hook";
import { Modal, Button, Form, Select, Input, Checkbox } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { regexNumber } from "../../../../../utils/convertTime";

const ModalDifferentSeat = (props) => {
  const {
    modalShow,
    setModalShow,
    differentFirst,
    index,
    applyDateFirst,
    name,
  } = props;
  const [form] = Form.useForm();
  const [plusOrMinus, setPlusOrMinus] = useState("");
  const [listDay, setListDay] = useState([]);
  const dayInWeek = [2, 3, 4, 5, 6, 7, 1];

  useEffect(() => {
    form.setFieldsValue({
      difference:
        (differentFirst[index] > 0 && !differentFirst[index].toString().startsWith("+"))
          ? regexNumber(`+${differentFirst[index]}`)
          : regexNumber(differentFirst[index]),
    });
    setListDay(applyDateFirst[index]?.map((item) => parseInt(item)) ?? []);
    differentFirst[index] > 0
      ? setPlusOrMinus("+")
      : differentFirst[index] < 0
      ? setPlusOrMinus("-")
      : setPlusOrMinus("");
  }, [differentFirst, applyDateFirst, modalShow]);

  const onTickCheckbox = (index) => {
    if (listDay.includes(index)) {
      setListDay(listDay.filter((day) => day !== index));
    } else {
      setListDay([...listDay, index]);
    }
  };
  const onTickAll = (e) => {
    if (e.target.checked) {
      setListDay(dayInWeek);
    } else {
      setListDay([]);
    }
  };

  return (
    <Modal
      open={modalShow}
      onOk={() => {
        applyDateFirst[index] = listDay.map((day) => day.toString());
        const value = form.getFieldValue("difference");
        differentFirst[index] = `${value}`;
        // form.resetFields();
        setModalShow(false);
      }}
      onCancel={() => setModalShow(false)}
      okText="Xác nhận"
      cancelText="Xóa"
      cancelButtonProps={{ className: "del-btn" }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <div className="flex flex-row justify-center space-x-2">
          <Button
            className="del-btn"
            onClick={() => {
              form.setFieldValue("difference", "");
              setListDay([]);
            }}
          >
            Xóa
          </Button>
          <OkBtn>Xác nhận</OkBtn>
        </div>
      )}
    >
      <Title level={5}>Thêm giá tiền phụ {name}</Title>
      <Form form={form} onFinish={(values) => onSubmitForm(values)}>
        <div className="flex flex-row h-8">
          <div className="flex flex-row">
            <div
              className={`${
                plusOrMinus == "+"
                  ? "bg-green-700 text-white"
                  : "bg-white text-green-700"
              } w-8 text-center text-xl border-green-700 border hover:bg-green-700 hover:text-white cursor-pointer`}
              onClick={() => {
                form.setFieldValue("difference", "+");
                setPlusOrMinus(plusOrMinus == "+" ? "" : "+");
              }}
            >
              +
            </div>
            <div
              className={`${
                plusOrMinus == "-"
                  ? "bg-green-700 text-white"
                  : "bg-white text-green-700"
              } w-8 text-center text-xl border-green-700 border hover:bg-green-700 hover:text-white cursor-pointer`}
              onClick={() => {
                form.setFieldValue("difference", "-");
                setPlusOrMinus(plusOrMinus == "-" ? "" : "-");
              }}
            >
              -
            </div>
          </div>
          <Form.Item name="difference">
            <Input placeholder="Nhập giá tiền" onBlur={(e) => form.setFieldValue("difference", regexNumber(form.getFieldValue("difference")))}/>
          </Form.Item>
        </div>

        <div className="my-2 flex flex-row space-x-4">
          <div className="flex flex-col items-center">
            <Checkbox onClick={(e) => onTickAll(e)} />
            <span>Tất cả</span>
          </div>

          {dayInWeek.map((day, index) => (
            <div key={index} className="flex flex-col items-center">
              <Checkbox
                onChange={(e) => onTickCheckbox(day)}
                checked={listDay.includes(day)}
              />
              <span>{day == 1 ? "CN" : `T${day}`}</span>
            </div>
          ))}
        </div>
      </Form>
    </Modal>
  );
};

export default ModalDifferentSeat;
