import { Dropdown, Space, Row, Col, Button } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import AvatarDropdown from "../AvatarDropdown";
import "./style.css";
import useRoles from "../../../../hooks/useRoles";
const AppNav = () => {
  const [status, setStatus] = useState("operating");
  const role = useRoles();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  useEffect(() => {
    if (
      (pathName == "/nhan-vien") |
      (pathName == "/bao-cao") |
      (pathName == "/thong-tin") |
      (pathName == "/booking")
    ) {
      setStatus("manage");
    }
    if (pathName == "/booking") {
      setStatus("ticket");
    }
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            setStatus("operating");
            navigate("/phuong-tien");
          }}
          className="text-sm font-quicksand"
        >
          Điều hành
        </a>
      ),
    },
  ];
  const itemsManage = [
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            setStatus("manage");
            navigate("/thong-tin");
          }}
          className="text-sm font-quicksand"
        >
          Quản lý
        </a>
      ),
    },
  ];
  const itemsSeller = [
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            setStatus("ticket");
            navigate("/booking");
          }}
          className="text-sm font-quicksand"
        >
          Bán vé
        </a>
      ),
    },
  ];

  const checkRole = () => {
    if (!role) return items;
    if (role.includes("ROLE_COMPANY"))
      return [...items, ...itemsManage, ...itemsSeller];
    if (
      role.includes("ROLE_MODERATOR_EMPLOYEE") &&
      role.includes("ROLE_SELLER_EMPLOYEE")
    )
      return items.concat(itemsSeller);
    if (role.includes("ROLE_SELLER_EMPLOYEE")) return itemsSeller;
    if (role.includes("ROLE_MODERATOR_EMPLOYEE")) return items;
  };
  return (
    <div className="w-full items-center justify-center ml-4 grid grid-cols-12 gap-4">
      <div className="col-span-2 text-white font-extrabold text-base">
        <Dropdown menu={{ items: checkRole() }}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {status === "operating"
                ? "Điều hành"
                : status == "ticket"
                ? "Bán vé"
                : "Quản lý"}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>
      <div className="col-span-9">
        {status === "operating" ? (
          <div className="bg-white flex w-full rounded h-9 items-center justify-around">
            <Link
              to="/phuong-tien"
              className={`${
                pathName == "/phuong-tien" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Phương tiện
            </Link>
            <Link
              to="/"
              className={`${
                pathName == "/" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Địa điểm
            </Link>
            <Link
              to="/chinh-sach"
              className={`${
                pathName == "/chinh-sach" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Chính sách
            </Link>
            <Link
              to="/lich-xuat-ben"
              className={`${
                pathName == "/lich-xuat-ben" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Lịch xuất bến
            </Link>
          </div>
        ) : status === "manage" ? (
          <div className="bg-white flex w-full justify-around rounded h-9 items-center">
            <Link
              to="/nhan-vien"
              className={`${
                pathName == "/nhan-vien" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Nhân viên
            </Link>
            <Link
              to="/thong-tin"
              className={`${
                pathName == "/thong-tin" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Thông tin hãng xe
            </Link>
            <Link
              to="/bao-cao"
              className={`${
                pathName == "/bao-cao" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Báo cáo
            </Link>
          </div>
        ) : (
          <div className="bg-white flex w-full justify-around rounded h-9 items-center">
            {/* <Link
              to="/ban-ve"
              className={`${
                pathName == "/ban-ve" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Bán vé
            </Link> */}
            <Link
              to="/booking"
              className={`${
                pathName == "/booking" ? "text-green-700" : null
              } hover:text-green-600 text-base`}
            >
              Booking
            </Link>
          </div>
        )}
      </div>
      <div className="col-span-1">
        <AvatarDropdown />
      </div>
    </div>
  );
};

export default AppNav;
