import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddPointToRoute from "../components/AddPointToRoute";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  requestLoadListRoute,
  setCurrentRoute,
} from "../../../../redux/slices/routeSlice";
import {
  requestLoadCoach,
  requestLoadListPoint,
  requestLoadOrder,
  requestLoadPolicy,
} from "../../../../redux/slices/companySlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../../utils/Loading";
import OfficeForm from "../components/OfficeForm";
import TravelPath from "../travelPath";
import ModalRoute from "../components/ModalRoute";
import "./style.css";

const Location = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const isLoading = useAppSelector((state) => state.routeState.loading);
  const [state, setState] = useState();

  useEffect(() => {
    handleLoadRoutes();
    dispatch(requestLoadOrder(companyId));
    dispatch(requestLoadCoach(companyId));
    dispatch(requestLoadPolicy(companyId));
    dispatch(requestLoadListPoint(companyId));
    dispatch(setCurrentRoute(null));
  }, []);

  async function handleLoadRoutes() {
    try {
      await dispatch(requestLoadListRoute(companyId));
    } catch (err) {
      console.log(err);
    }
  }

  const LIST_STATE = [
    <AddPointToRoute />,
    <OfficeForm />,
    <TravelPath />,
    <ModalRoute />,
  ];

  return (
    <div className="px-8 space-y-4">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div>
          <div className="bg-white w-full px-6 py-4 rounded-md grid grid-cols-4 gap-2">
            
            <Tooltip
              title="Tạo các văn phòng, đại lý của nhà xe"
              placement="bottomLeft"
            >
              <Button
                onClick={() => setState(2)}
                className="col-span-1 text-lg office-btn h-12 border rounded-md"
                icon={<PlusOutlined />}
              >
                Văn phòng
              </Button>
            </Tooltip>
            <Tooltip
              title="Chọn các quận/huyện mà nhà xe có hoạt động đón trả khách"
              placement="bottomLeft"
            >
              <Button
                onClick={() => setState(1)}
                className="col-span-1 h-12 text-lg template-btn border rounded-md"
                icon={<PlusOutlined />}
              >
                Quận / Huyện
              </Button>
            </Tooltip>
            <Tooltip
              title="Tạo lộ trình chi tiết các nơi xe có đi qua"
              placement="bottomLeft"
            >
              <Button
                onClick={() => setState(3)}
                className="col-span-1 text-lg route-btn h-12 border rounded-md"
                icon={<PlusOutlined />}
              >
                Lộ trình
              </Button>
            </Tooltip>
            <Button
              onClick={() => setState(4)}
              className="col-span-1 text-lg h-12 border rounded-md"
              icon={<PlusOutlined />}
            >
              Tuyến
            </Button>
          </div>
          <div className="mt-4">
            {state ? !!state && LIST_STATE[state - 1] : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default Location;
