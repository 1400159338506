import { Select, Input, Button, Tooltip } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  DoubleRoom,
} from "../../../../../../assets/svgs";
import { useEffect, useState } from "react";
import "./style.css";
import { useAppSelector } from "../../../../../../redux/hook";
import ModalPriceDiff from "./ModalPriceDiff";
import {
  deleteDot,
  regexNumber,
  setUniqueArrayOption,
} from "../../../../../../utils/convertTime";
import ModalSeatConfig from "./ModalSeatConfig";

const MapCarPrice = (props) => {
  const {
    index,
    listSection,
    setListSection,
    mainRoute,
    setDisable,
    limitOption,
    setLimitOption,
    routeId,
    coachTypeId,
  } = props;
  const [listMap, setListMap] = useState([]);
  const [secondPriceMap, setSecondPriceMap] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [currentCoach, setCurrentCoach] = useState(0);
  const [modalSeatConfig, setModalSeatConfig] = useState(false);
  const [currentCoachType, setCurrentCoachType] = useState(0);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const defaultCoach = [
    {
      value: 0,
      label: "Chọn loại xe",
    },
  ];
  const optionsListCoach = setUniqueArrayOption(
    defaultCoach.concat(
      listCoach.map((coach) => ({
        value: coach.coachType.id,
        label: coach.coachType.name,
      }))
    )
  );
  const onAdd = () => {
    setListMap((prev) => [...prev, [0, 0]]);
  };

  const onChangeSelect = (e, mapIndex) => {
    setCurrentCoach(e);
    if (mainRoute) {
      const check = limitOption.find((item) => item.value == e);
      if (!check) {
        setLimitOption(
          optionsListCoach
            .filter((option) => option.value == e)
            .concat(limitOption)
        );
      }
    }

    listMap[mapIndex][0] = e;
    if (mainRoute) {
      const tmp = {
        ...listSection[index],
        priceMap: listMap,
        type: 0,
      };
      setListSection([
        ...listSection.slice(0, index),
        tmp,
        ...listSection.slice(index + 1),
      ]);
    } else {
      const tmp = {
        ...listSection[index],
        priceMap: listMap,
        type: 1,
      };
      setListSection([
        ...listSection.slice(0, index),
        tmp,
        ...listSection.slice(index + 1),
      ]);
      setDisable(false);
    }

    // setListMap([
    //     ...listMap.slice(0, mapIndex),
    //     listMap[mapIndex],
    //     ...listMap.slice(mapIndex + 1)
    // ])
  };

  const onChangeInput = (e, mapIndex) => {
    console.log("first", e)
    listMap[mapIndex][1] = e;
    if (mainRoute) {
      const tmp = {
        ...listSection[index],
        priceMap: listMap,
        type: 0,
      };
      setListSection([
        ...listSection.slice(0, index),
        tmp,
        ...listSection.slice(index + 1),
      ]);
    } else {
      const tmp = {
        ...listSection[index],
        priceMap: listMap,
        type: 1,
      };
      setListSection([
        ...listSection.slice(0, index),
        tmp,
        ...listSection.slice(index + 1),
      ]);
    }
    setDisable(false);
  };

  const onChangeSecondInput = (e, coachId, mapIndex) => {
    console.log("second", e)
    const scPriceMap = [coachId, e];
    const findScPriceMap = secondPriceMap.findIndex(
      (item) => item[0] == coachId
    );
    if (findScPriceMap != -1) {
      secondPriceMap[findScPriceMap] = scPriceMap;
    } else {
      secondPriceMap.push(scPriceMap);
    }

    if (mainRoute) {
      const tmp = {
        ...listSection[index],
        secondPriceMap: secondPriceMap,
        type: 0,
      };
      setListSection([
        ...listSection.slice(0, index),
        tmp,
        ...listSection.slice(index + 1),
      ]);
    } else {
      const tmp = {
        ...listSection[index],
        secondPriceMap: secondPriceMap,
        type: 1,
      };
      setListSection([
        ...listSection.slice(0, index),
        tmp,
        ...listSection.slice(index + 1),
      ]);
    }
    setDisable(false);
  };

  const onDeletePriceMap = (indexVariable) => {
    const tmp = listMap.filter((_, i) => i !== indexVariable);
    const tmp1 = {
      ...listSection[index],
      priceMap: tmp,
    };
    setListSection([
      ...listSection.slice(0, index),
      tmp1,
      ...listSection.slice(index + 1),
    ]);
    setListMap((prev) => prev.filter((_, i) => i !== index));
    setDisable(false);
  };

  useEffect(() => {
    setListMap([]);
    const tmp = mainRoute
      ? listSection.filter((section) => section.type == 0)[0]?.priceMap
      : listSection[index].priceMap;
    if (tmp) {
      setListMap(tmp);
    } else {
      setListMap((prev) => [...prev, [0, 0]]);
    }
    const tmpSecond = mainRoute
      ? listSection.filter((section) => section.type == 0)[0]?.secondPriceMap
      : listSection[index].secondPriceMap;
    if (tmpSecond) {
      setSecondPriceMap(tmpSecond);
    } else {
      setSecondPriceMap((prev) => [...prev, [0, 0]]);
    }
    // fetch data
  }, [listSection]);

  return (
    <div className="col-span-5 space-y-2">
      {listMap.map((map, mapIndex) => (
        <div
          key={mapIndex}
          className="grid grid-cols-12 w-full space-x-1 cols-span-12"
        >
          <Select
            placeholder="Chọn loại xe"
            className={`map-select ${mainRoute ? "col-span-6" : "col-span-7"}`}
            options={mainRoute ? optionsListCoach : limitOption}
            value={parseInt(map[0]) ? parseInt(map[0]) : "Chọn loại xe"}
            onChange={(e) => onChangeSelect(e, mapIndex)}
            style={
              {
                // maxWidth: mainRoute ? "240px" : "240px",
                // minWidth: mainRoute ? "240px" : "240px",
              }
            }
          />
          <div className="flex flex-col col-span-4">
            <Input
              prefix={<UserOutlined />}
              placeholder="-------"
              className="map-input"
              value={regexNumber(map[1] ?? 0)}
              onChange={(e) =>
                onChangeInput(deleteDot(e.target.value), mapIndex)
              }
              maxLength={12}
            />

            {(parseInt(map[0]) < 15 && parseInt(map[0])) > 7 && (
              <Input
                prefix={<TeamOutlined />}
                placeholder="-------"
                className="map-input"
                value={regexNumber(
                  secondPriceMap.find((item) => item[0] == map[0])?.[1] ?? 0
                )}
                onChange={(e) =>
                  onChangeSecondInput(
                    deleteDot(e.target.value),
                    map[0],
                    mapIndex
                  )
                }
                maxLength={12}
              />
            )}
          </div>
          <div
            className={`relative ${mainRoute ? "col-span-2" : "col-span-1"}`}
          >
            {!!mainRoute && (
              <Tooltip
                title="Tạo thêm giá vé phụ cho các vị trí đẹp hoặc xấu"
                className="relative"
              >
                <Button
                  className={`pause-btn w-3 absolute ${
                    listMap[mapIndex][0] > 7 && listMap[mapIndex][0] < 15
                      ? "-top-1.5"
                      : ""
                  }`}
                  onClick={() => {
                    setCurrentCoach(listMap[mapIndex][0]);
                    setModalShow(true);
                  }}
                >
                  $
                </Button>
              </Tooltip>
            )}
            {!!mainRoute &&
              listMap[mapIndex][0] > 7 &&
              listMap[mapIndex][0] < 15 && (
                <Tooltip title="Tùy chọn phòng đôi">
                  <Button
                    onClick={() => {
                      setModalSeatConfig(true);
                      setCurrentCoachType(listMap[mapIndex][0]);
                    }}
                    icon={<DoubleRoom />}
                    className="office-btn"
                  />
                </Tooltip>
              )}
            <Button
              onClick={() => onDeletePriceMap(mapIndex)}
              className={`w-3 absolute ${
                listMap[mapIndex][0] > 7 && listMap[mapIndex][0] < 15 ? "" : ""
              }`}
            >
              X
            </Button>
          </div>
        </div>
      ))}
      <Button onClick={onAdd}>Thêm loại xe</Button>
      <ModalPriceDiff
        modalShow={modalShow}
        setModalShow={setModalShow}
        coachId={currentCoach}
        listSection={listSection}
        setListSection={setListSection}
        routeId={routeId}
      />
      <ModalSeatConfig
        routeId={routeId}
        modalShow={modalSeatConfig}
        setModalShow={setModalSeatConfig}
        coachTypeId={currentCoachType}
      />
    </div>
  );
};

export default MapCarPrice;
