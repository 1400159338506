import TimeSlotCard from "../components/TimeSlotCard";
import TimeSlotCardV2 from "../components/ModalTimeslot/TimeslotCardV2";
import {
  Card,
  Button,
  Select,
  Divider,
  Typography,
  Row,
  DatePicker,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  requestLoadListRoute,
  requestLoadRouteTemplate,
  requestLoadTravelPath,
  setChooseCoach,
  setCurrentRoute,
} from "../../../../redux/slices/routeSlice";
import { useEffect, useState } from "react";
import SubSchedule from "../components/SubSchedule";
import {
  apiCreateSchedule,
  apiCreateTimeslot,
  apiGetListTimeslotByDate,
} from "../../../../api/services";
import moment from "moment";
const { Title } = Typography;
import {
  convertSecondsToDayjs,
  deleteDot,
} from "../../../../utils/convertTime";
import "./style.css";
import dayjs from "dayjs";
import { ModalTimeslot } from "../components/ModalTimeslot";
import { requestLoadCoach } from "../../../../redux/slices/companySlice";
import { enqueueSnackbar } from "notistack";
import { requestLoadSubSchedule } from "../../../../redux/slices/scheduleSlice";

const Schedule = () => {
  const dispatch = useAppDispatch();
  const [isCreate, setIsCreate] = useState(false);
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const listRoute = useAppSelector((state) => state.routeState.listRoute);
  const currentRoute = useAppSelector((state) => state.routeState.currentRoute);
  const listSubSchedule = useAppSelector(
    (state) => state.scheduleState.listSubSchedule
  );
  const [currentDate, setCurrentDate] = useState(
    dayjs(new Date()).startOf("day").valueOf()
  );
  const [selectOption, setSelectOption] = useState([]);
  const [currentTimeslot, setCurrentTimeslot] = useState();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [listTimeSlot, setListTimeSlot] = useState([]);
  const [listSubTimeslot, setListSubTimeslot] = useState([]);
  const [extraTimeslot, setExtraTimeslot] = useState(false);
  const [isMain, setIsMain] = useState(true);
  const [listSubScheduleIdx, setListSubScheduleIdx] = useState([]);
  useEffect(() => {
    dispatch(requestLoadCoach(companyId));
    // dispatch(requestLoadListRoute(companyId));
    dispatch(setCurrentRoute(0));
    handleLoadRoutes();
  }, []);

  useEffect(() => {
    const tmp = listRoute?.map((route) => ({
      value: route.id,
      label: `${route?.startPoint.district} ${route?.startPoint.province} - ${route?.endPoint.district} ${route?.endPoint.province}`,
    }));
    setSelectOption(tmp);
  }, [listRoute]);

  // useEffect(() => {
  //   handleLoadTimeslot({ date: currentDate, coachRouteId: currentRoute });
  // }, [extraTimeslot]);

  useEffect(() => {
    setListSubScheduleIdx(listSubSchedule);
  }, [listSubSchedule]);

  async function handleLoadRoutes() {
    try {
      await dispatch(requestLoadListRoute(companyId));
      await dispatch(requestLoadTravelPath(companyId));
    } catch (err) {
      console.log(err);
    }
  }
  async function handleLoadTimeslot(props) {
    // setIsMain(true);
    if (!isMain) {
      dispatch(
        requestLoadSubSchedule({
          coachRouteId: props.coachRouteId,
          date: 0,
        })
      );
      return;
    }
    const res = await apiGetListTimeslotByDate(props);
    if (res.data.error == 0 && res.data.data.length != 0) {
      const tmp = res.data.data.filter((tl) => tl.coachSchedule?.type == 0);
      const subTmp = res.data.data.filter((tl) => tl.coachSchedule?.type == 1);
      const booster = res.data.data.filter((tl) => tl.type == 1);
      setCurrentTimeslot(tmp[0]);
      setCurrentIndex(0);
      setListTimeSlot([...tmp, ...booster]);
      setListSubTimeslot(subTmp);
    } else {
      setCurrentTimeslot();
      setCurrentIndex(-1);
      setListTimeSlot([]);
      setListSubTimeslot([]);
    }
  }
  const handleCreateMainSchedule = async (e) => {
    const tmp = listTimeSlot.map((timeslot) => {
      const coach = listCoach.find((coach) => coach.id == timeslot.coachId);
      const coachId = coach?.coachType.id;
      const tmp_1 = timeslot.sectionList?.map((section) => {
        return {
          ...section,
          price: section.price ? section.price : section.priceMap[coachId],
          secondPrice: section.secondPrice
            ? section.secondPrice
            : section.secondPriceMap[coachId],
          templateId: section.id,
        };
      });
      return {
        ...timeslot,
        price: deleteDot(timeslot.price),
        secondPrice: deleteDot(timeslot.secondPrice),
        sectionList: tmp_1,
      };
    });
    e.preventDefault();
    const time = moment().startOf("day").valueOf();
    const data = {
      coachRouteId: currentRoute,
      createTimeslotRequestList: tmp,
      date: time,
      name: "Lịch chính",
      startTime: 0,
      endTime: 0,
      type: 0,
    };
    const res = await apiCreateSchedule(data);
    if (res.data.error == 0) {
      enqueueSnackbar("Tạo lịch thành công", {
        variant: "success",
      });
      setListTimeSlot([]);
      handleChooseRoute(currentRoute);
    }
  };

  const handleCreateTimeslot = async () => {
    let tmp = listTimeSlot.length - 1;
    const coach = listCoach.find(
      (coach) => coach.id == listTimeSlot[tmp].coachId
    );
    const coachId = coach?.coachType.id;
    const sectionList = listTimeSlot[tmp].sectionList.map((section) => {
      return {
        ...section,
        price: section.priceMap[coachId],
        templateId: section.id,
      };
    });
    const data = {
      ...listTimeSlot[tmp],
      sectionList: sectionList,
      coachScheduleId: listTimeSlot[0].coachSchedule.id,
      coachRouteId: currentRoute,
      type: 0,
    };
    const res = await apiCreateTimeslot(data);
    if (!res.data.error) {
      setListTimeSlot([]);
      handleChooseRoute(currentRoute);
    }
  };

  const handleChooseRoute = async (value) => {
    await dispatch(setCurrentRoute(value));
    dispatch(requestLoadRouteTemplate(value));
    handleLoadTimeslot({ date: currentDate, coachRouteId: value });

    setIsCreate(false);
  };

  const lunarToGregorian = (lunarDate) => {
    return lunarDate.lunar().toDate();
  };

  return (
    <div className="px-8 space-y-4">
      <Card
        extra={
          <DatePicker
            popupClassName="calendar-popup"
            cellRender={(value) => (
              <div className="relative">
                <p className="top-0 left-0">{value.format("DD")}</p>
                <p
                  className={`absolute text-[10px] -bottom-2 -right-0 ${
                    dayjs(
                      moment()
                        .year(value.$y)
                        .month(value.$M)
                        .date(value.$D)
                        .lunar()
                    ).$D == 1
                      ? "text-red-500 -right-1"
                      : "text-gray-400"
                  }`}
                >
                  {moment()
                    .year(value.$y)
                    .month(value.$M)
                    .date(value.$D)
                    .lunar()
                    .format(
                      dayjs(
                        moment()
                          .year(value.$y)
                          .month(value.$M)
                          .date(value.$D)
                          .lunar()
                      ).$D == 1
                        ? "D/M"
                        : "DD"
                    )}
                </p>
              </div>
            )}
            format="DD/ MM/ YY"
            defaultValue={dayjs(new Date())}
            onChange={(e) => {
              setCurrentDate(e.startOf("day").valueOf());
              handleLoadTimeslot({
                date: e.startOf("day").valueOf(),
                coachRouteId: currentRoute,
              });
            }}
          />
        }
      >
        <Row className="items-center space-x-6 mb-4">
          <Title level={5}>Tuyến xe</Title>
          <Select
            defaultValue="Chọn tuyến xe"
            options={selectOption}
            style={{ width: 500, height: 40 }}
            onSelect={(value) => handleChooseRoute(value)}
          ></Select>
          {isMain && (
            <div className="flex flex-row space-x-2">
              <div className="flex justify-end space-x-4">
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#006D38",
                    borderRadius: 4,
                  }}
                  disabled={!currentRoute}
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    setIsCreate(true);
                    setCurrentIndex(listTimeSlot.length);
                    setCurrentTimeslot({});
                    dispatch(setChooseCoach(0));
                    setListTimeSlot([...listTimeSlot, {}]);
                  }}
                >
                  Giờ cố định
                </Button>
                <Button
                  disabled={!currentRoute}
                  onClick={() => {
                    dispatch(setChooseCoach(0));
                    setExtraTimeslot(true);
                  }}
                  icon={<PlusCircleOutlined />}
                  style={{
                    backgroundColor: "white",
                    color: "#006D38",
                    borderRadius: 4,
                  }}
                >
                  Tăng cường
                </Button>
              </div>
              <p className="text-lg">{listTimeSlot.length} chuyến</p>
            </div>
          )}
        </Row>
        <div className="flex flex-row space-x-2 overflow-x-auto">
          {listTimeSlot?.map((sh, index) =>
            sh.status ? (
              <div
                className="w-fit relative"
                onClick={() => {
                  setCurrentTimeslot(sh);
                  setCurrentIndex(index);
                }}
              >
                <Button
                  key={index}
                  className={`${
                    index != currentIndex ? "un-choose-btn" : "active"
                  } off-timeslot`}
                >
                  {sh.departureTime || sh.departureTime == 0
                    ? dayjs(convertSecondsToDayjs(sh.departureTime)).format(
                        "HH:mm"
                      )
                    : "--:--"}
                </Button>
                {!!sh.type && (
                  <div className="absolute w-fit text-xs text-white -top-1 -right-2 border-black p-1 bg-red-500 rounded-full cursor-pointer">
                    TC
                  </div>
                )}

                <div className="w-full h-[2px] bg-red-500 absolute top-1/2 cursor-pointer"></div>
              </div>
            ) : (
              <div>
                {sh.type ? (
                  <div
                    className="w-fit relative"
                    onClick={() => {
                      setCurrentTimeslot(sh);
                      setCurrentIndex(index);
                    }}
                  >
                    <Button
                      key={index}
                      className={index != currentIndex ? "un-choose-btn" : ""}
                    >
                      {sh.departureTime || sh.departureTime == 0
                        ? dayjs(convertSecondsToDayjs(sh.departureTime)).format(
                            "HH:mm"
                          )
                        : "--:--"}
                    </Button>
                    <div className="absolute w-fit text-xs text-white -top-1 -right-2 border-black p-1 bg-red-500 rounded-full cursor-pointer">
                      TC
                    </div>
                  </div>
                ) : (
                  <Button
                    key={index}
                    onClick={() => {
                      setCurrentTimeslot(sh);
                      setCurrentIndex(index);
                    }}
                    className={index != currentIndex ? "un-choose-btn" : ""}
                  >
                    {sh.departureTime || sh.departureTime == 0
                      ? dayjs(convertSecondsToDayjs(sh.departureTime)).format(
                          "HH:mm"
                        )
                      : "--:--"}
                  </Button>
                )}
              </div>
            )
          )}
        </div>
        <div>
          {currentTimeslot && (
            <div>
              {currentTimeslot?.type ? (
                <TimeSlotCardV2
                  schedule={currentTimeslot}
                  index={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  listTimeSlot={listTimeSlot}
                  setListTimeSlot={setListTimeSlot}
                  setCurrentTimeslot={setCurrentTimeslot}
                  currentDate={currentDate}
                  extraTimeslot={extraTimeslot}
                />
              ) : (
                <TimeSlotCard
                  schedule={currentTimeslot}
                  index={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  listTimeSlot={listTimeSlot}
                  setListTimeSlot={setListTimeSlot}
                  setCurrentTimeslot={setCurrentTimeslot}
                  currentDate={currentDate}
                  extraTimeslot={extraTimeslot}
                />
              )}
            </div>
          )}

          <Divider />
          <Row className="justify-center">
            {isCreate && !currentTimeslot?.id && (
              <Button
                onClick={(e) =>
                  listTimeSlot[0]?.coachSchedule
                    ? handleCreateTimeslot()
                    : handleCreateMainSchedule(e)
                }
              >
                Hoàn thành
              </Button>
            )}
          </Row>
        </div>
        {/* <Card className="space-y-4">
          <SubSchedule
            listSubTimeslot={listSubTimeslot}F
            schedule={listSubTimeslot[0].coachSchedule ?? {}}
            setListSubTimeslot={setListSubTimeslot}
          />
        </Card> */}
      </Card>

      {extraTimeslot && (
        <ModalTimeslot
          modalShow={extraTimeslot}
          setModalShow={setExtraTimeslot}
        />
      )}
    </div>
  );
};

export default Schedule;
