import {
  chooseDate,
  requestLoadTimeslotByDate,
  setListTicket,
} from "../../../../../redux/slices/ticketSlice";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import { Button, DatePicker, Input, Select, Modal } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { setCurrentRoute } from "../../../../../redux/slices/routeSlice";
import ModalSearchTicket from "./searchTicket/ModalSearchTicket";
import {
  IconFastTicket,
  IconRoll,
  IconRollCall,
} from "../../../../../assets/svgs";

const Header = (props) => {
  const { rollCall, setRollCall } = props;
  const [route, setRoute] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()).startOf("day")); //thoi diem dau ngay
  const [vsc, setVsc] = useState();
  const [isShow, setIsShow] = useState(false);
  const dispatch = useAppDispatch();
  const listRoute = useAppSelector((state) => state.routeState.listRoute);
  const currentTimeslot = useAppSelector(
    (state) => state.ticketState.currentTimeslot
  );
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const optionsRoute = listRoute.map((route) => ({
    value: route.id,
    label: `${route.startPoint.province} - ${route.endPoint.province}`,
  }));
  // useEffect(() => {
  //     dispatch(requestLoadListRoute(companyId))
  // }, [])

  useEffect(() => {
    dispatch(chooseDate(date));
  }, [date]);

  useEffect(() => {
    dispatch(setListTicket([]))
  }, [])

  useEffect(() => {
    const tmp = listCoach.find((coach) => coach.id == currentTimeslot.coachId);
    setVsc(tmp?.vsc);
  }, [currentTimeslot]);

  return (
    <div className="flex flex-row w-full justify-between bg-[#80B155] p-2 rounded-md">
      <div className="flex flex-row space-x-2">
        <Input placeholder="Tìm kiếm vé" className="w-40" />
        <Select
          placeholder="Chọn tuyến xe"
          options={optionsRoute}
          className="w-60"
          onSelect={(value) => {
            dispatch(setCurrentRoute(value));
            setRoute(value);
            dispatch(
              requestLoadTimeslotByDate({
                coachRouteId: value,
                date: date.valueOf(),
              })
            );
            dispatch(setListTicket([]));
          }}
        />
        <DatePicker
          format="DD/ MM/ YY"
          defaultValue={dayjs(new Date())}
          className="w-32"
          onChange={(e) => {
            setDate(dayjs(e).startOf("day"));
            dispatch(
              requestLoadTimeslotByDate({
                coachRouteId: route,
                date: dayjs(e).startOf("day").valueOf(),
              })
            );
            dispatch(setListTicket([]));
          }}
        />
        <Button
          onClick={() => setIsShow(true)}
          style={{ backgroundColor: "red" }}
          icon={<IconFastTicket />}
        >
          Đặt vé nhanh
        </Button>
      </div>
      <div className="flex flex-row space-x-2">
        <Input value={vsc} />
        <Button
          style={{
            backgroundColor: "#F0DBAF",
            color: "black",
          }}
        >
          In phơi
        </Button>
        <Button
          onClick={() => setRollCall(!rollCall)}
          style={{
            backgroundColor: "white",
          }}
        >
          <IconRoll />
        </Button>
      </div>
      <Modal
        open={isShow}
        onCancel={() => setIsShow(false)}
        width={800}
        footer={null}
      >
        <ModalSearchTicket />
      </Modal>
    </div>
  );
};

export default Header;
