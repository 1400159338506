import PolicyCard from "../components/PolicyCard";
import { Form, Input, Row, Card, Typography, Button, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  requestCreatePolicy,
  requestLoadPolicy,
} from "../../../../redux/slices/companySlice";
import CreatePolicy from "./CreatePolicy";
const { Title } = Typography;
const { TextArea } = Input;

const Policy = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const id = useAppSelector((state) => state.authState.userInfo.id);
  const [modalShow, setModalShow] = useState(false);
  const listPolicy = useAppSelector((state) => state.companyState.listPolicy);
  const [state, setState] = useState(0);
  const [form] = Form.useForm();

  const policyName = [
    "Tạo chính sách",
    "Chính sách vé",
    "Chính sách website/app",
    "Chính sách hàng hóa",
  ];

  useEffect(() => {
    dispatch(requestLoadPolicy(id));
  }, []);

  return (
    <div className="px-20">
      <div className="bg-white rounded-lg h-20 w-full grid grid-cols-5 items-center px-8">
        <Title level={4} className="col-span-1">
          {policyName[state]}
        </Title>
        <div className="bg-white w-full col-span-4 px-6 py-4 rounded-md gap-2 flex justify-end">
          <Button
            onClick={() => setState(1)}
            className="h-12 text-md template-btn border rounded-md w-[200px]"
            icon={<PlusOutlined />}
          >
            Chính sách vé
          </Button>

          <Button
            onClick={() => setState(2)}
            className="text-md h-12 policy-btn border rounded-md"
            icon={<PlusOutlined />}
          >
            Chính sách website/app
          </Button>
          <Button
            onClick={() => setState(3)}
            className="col-span-4 text-md h-12 border rounded-md"
            icon={<PlusOutlined />}
          >
            Chính sách hàng hóa
          </Button>
        </div>
      </div>
      <div>
        <CreatePolicy 
        type={state}
        policy={listPolicy.find((p) => p.type == state)}
        />
      </div>
      {/* <div className="mt-6 space-y-2">
        {listPolicy?.map((p, index) => (
          <PolicyCard key={index} policy={p} />
        ))}
      </div> */}
    </div>
  );
};

export default Policy;
