import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import { Form, Input, Button, Select } from "antd";
import {
  requestLoadDropOffPoint,
  requestLoadPickUpPoint,
  requestFindCoachOnTimeslot,
  requestLoadTicket,
} from "../../../../../redux/slices/ticketSlice";
import {
  apiCreateSeatOrder,
  apiGetOrderById,
  apiUpdateSeatOrder,
} from "../../../../../api/services";
import { useSnackbar } from "notistack";

const TicketForm = (props) => {
  const { seatName, orderId, setModalShow, currentSeat } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const employeeName = useAppSelector((state) => state.authState.userInfo.name);
  const currentTimeslot = useAppSelector(
    (state) => state.ticketState.currentTimeslot
  );
  const listOffice = useAppSelector((state) => state.companyState.listOffice);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const optionsOffice = listOffice.map((office) => ({
    label: office.name,
    value: office.id,
  }));
  const pickUpPoint = useAppSelector((state) => state.ticketState.pickUpPoint);
  const dropOffPoint = useAppSelector(
    (state) => state.ticketState.dropOffPoint
  );
  const coachOnTimeslot = useAppSelector(
    (state) => state.ticketState.coachOnTimeslot
  );
  const currentDate = useAppSelector((state) => state.ticketState.currentDate);
  const optionsSeatStatus = [
    {
      value: 0,
      label: "Chưa thanh toán",
    },
    {
      value: 1,
      label: "Đã thanh toán",
    },
  ];

  const onCreateSeat = async () => {
    const tmp = form.getFieldsValue();
    const data = {
      companyId: companyId,
      departureDate: currentDate.valueOf(),
      departureTime: coachOnTimeslot.departureTime,
      price: coachOnTimeslot.price,
      timeslotId: currentTimeslot.id,
      sectionId: coachOnTimeslot.sectionId,
      seat: {
        name: seatName,
        timeslotId: currentTimeslot.id,
      },
      updateBy: employeeName,
      ...tmp,
    };
    const res = await apiCreateSeatOrder(data);
    if (!res.data.error) {
      form.resetFields();
      setModalShow(false);
      enqueueSnackbar("Tạo vé thành công", { variant: "success" });
      const coach = listCoach.find(
        (coach) => coach.id == currentTimeslot.coachId
      );
      dispatch(
        requestLoadTicket({
          companyId,
          coachVsc: coach?.vsc,
          timeslotDepartureTime: currentTimeslot?.departureTime,
        })
      );
    }
  };
  const onUpdateSeat = async () => {
    const tmp = form.getFieldsValue();
    const data = {
      id: orderId,
      companyId: companyId,
      departureDate: currentDate.valueOf(),
      departureTime: coachOnTimeslot.departureTime,
      price: coachOnTimeslot.price,
      timeslotId: currentTimeslot.id,
      sectionId: coachOnTimeslot.sectionId,
      seat: {
        name: seatName,
        timeslotId: currentTimeslot.id,
      },
      updateBy: employeeName,
      ...tmp,
    };
    const res = await apiUpdateSeatOrder(data);
    if (!res.data.error) {
      form.resetFields();
      setModalShow(false);
      enqueueSnackbar("Cập nhật vé thành công", { variant: "success" });
    }
  };

  const onSelect = (props) => {
    const { pointId, type } = props;
    type
      ? dispatch(
          requestLoadDropOffPoint({
            timeslotId: currentTimeslot.id,
            pickUpPointId: pointId,
          })
        )
      : dispatch(
          requestLoadPickUpPoint({
            timeslotId: currentTimeslot.id,
            dropOffPointId: pointId,
          })
        );
    onFindCoachOnTimeslot();
  };

  const onFindCoachOnTimeslot = () => {
    const startPointId = form.getFieldValue("pickUpPointId");
    const endPointId = form.getFieldValue("dropOffPointId");
    if (!startPointId || !endPointId) return;
    dispatch(
      requestFindCoachOnTimeslot({
        timeslotId: currentTimeslot.id,
        startPointId: startPointId,
        endPointId: endPointId,
      })
    );
  };

  const onGetOrderById = async () => {
    if (!orderId) {
      form.resetFields();
      return;
    }
    const res = await apiGetOrderById(orderId);
    if (!res.data.error) {
      dispatch(
        requestFindCoachOnTimeslot({
          timeslotId: currentTimeslot.id,
          startPointId: res.data.data.pickUpPointId,
          endPointId: res.data.data.dropOffPointId,
        })
      );
      form.setFieldsValue(res.data.data);
    }
  };

  useEffect(() => {
    dispatch(
      requestLoadDropOffPoint({
        timeslotId: currentTimeslot.id,
        pickUpPointId: "",
      })
    );
    dispatch(
      requestLoadPickUpPoint({
        timeslotId: currentTimeslot.id,
        dropOffPointId: "",
      })
    );
  }, [currentTimeslot]);
  useEffect(() => {
    onGetOrderById();
  }, [currentSeat]);

  return (
    <div>
      <Form form={form}>
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-1 flex flex-col">
            <div className="h-16">
              <span>Điện thoại:</span>
              <Form.Item name={"phoneNumber"}>
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>VP đón:</span>
              <Form.Item name={"pickUpOfficeId"}>
                <Select options={optionsOffice} />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>T/C đón:</span>
              <Form.Item name={"pickUpPointId"}>
                <Select
                  options={pickUpPoint}
                  onSelect={(e) => onSelect({ pointId: e, type: 1 })}
                />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Đón DĐ:</span>
              <Form.Item name={"pickUpTransit"}>
                <Input placeholder=" " />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Phụ thu:</span>
              <Form.Item name={"surcharge"}>
                <Input placeholder=" " suffix={"Đ"} />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Cọc trước:</span>
              <Form.Item name={"deposit"}>
                <Input placeholder=" " suffix={"Đ"} />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Thanh toán:</span>
              <Form.Item name={"status"}>
                <Select options={optionsSeatStatus} />
              </Form.Item>
            </div>
          </div>
          <div className="col-span-1 flex flex-col">
            <div className="h-16">
              <span>Họ tên:</span>
              <Form.Item name={"passengerName"}>
                <Input placeholder=" " />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>VP trả:</span>
              <Form.Item name={"dropOffOfficeId"}>
                <Select options={optionsOffice} />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>T/C trả:</span>
              <Form.Item name={"dropOffPointId"}>
                <Select
                  options={dropOffPoint}
                  onSelect={(e) => onSelect({ pointId: e, type: 0 })}
                />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Trả DĐ:</span>
              <Form.Item name={"dropOffTransit"}>
                <Input />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Giảm giá:</span>
              <Form.Item name={"discount"}>
                <Input placeholder=" " suffix={"Đ"} />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Đại lý:</span>
              <Form.Item name={"state"}>
                <Input placeholder=" " />
              </Form.Item>
            </div>
            <div className="h-16">
              <span>Ghi chú:</span>
              <Form.Item name={"note"}>
                <Input placeholder="" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center space-x-2">
          <Button>Quay lại</Button>
          <Button onClick={onCreateSeat}>Thêm vé</Button>
          {/* <Button onClick={() => console.log(pickUpPoint)}>In T/C</Button> */}
          {/* <Button onClick={() => console.log(dropOffPoint)}>In vé</Button> */}
          <Button onClick={onUpdateSeat}>Cập nhật</Button>
        </div>
      </Form>
    </div>
  );
};

export default TicketForm;
