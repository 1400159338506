import { useAppSelector } from "../../../../../redux/hook";
import { useState, useEffect } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button } from "antd";
import CoachType from "./CoachType";

const SeatNumber = () => {
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const [listTypeCoach, setListTypeCoach] = useState([]);
  const [currentCoachType, setCurrentCoachType] = useState(0);

  const onChooseType = (type) => {
    setCurrentCoachType(type);
  };

  useEffect(() => {
    const tmp = listCoach.map((coach) => coach.coachType);
    const uniqueTypeCoach = tmp.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    setListTypeCoach(uniqueTypeCoach);
  }, []);

  return (
    <div className="grid grid-cols-3 gap-8 w-full">
      <div className="h-[75vh] min-h-fit overflow-y-scroll col-span-1 bg-white rounded-md p-2">
        {listTypeCoach.map((type, index) => (
          <div
            key={index}
            className={`flex flex-row items-center justify-between px-4 py-2 cursor-pointer rounded-md ${
              type.id == currentCoachType ? "bg-green-200" : ""
            }`}
            onClick={() => onChooseType(type.id)}
          >
            <p className="overflow-hidden">{type.name}</p>
            <Button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "red",
              }}
              icon={<DeleteFilled />}
            >
              Xóa
            </Button>
          </div>
        ))}
      </div>

      <div className="col-span-2 bg-white w-full min-h-fit rounded-md p-4">
        <CoachType coachType={currentCoachType} />
      </div>
    </div>
  );
};

export default SeatNumber;
