import {
  Button,
  Typography,
  Row,
  DatePicker,
  Divider,
  Input,
  Collapse,
} from "antd";
import {
  PlusCircleOutlined,
  ArrowRightOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { useEffect, useState } from "react";
const { Title } = Typography;
import TimeSlotCardV2 from "../ModalTimeslot/TimeslotCardV2";
// import TimeSlotCard from "../TimeSlotCard";
import {
  apiCreateSchedule,
  apiCreateSubSchedule,
  apiCreateTimeslot,
  apiDeleteSubSchedule,
} from "../../../../../api/services";
import dayjs from "dayjs";
import { convertSecondsToDayjs, deleteDot } from "../../../../../utils/convertTime";
import { setChooseCoach } from "../../../../../redux/slices/routeSlice";
import { useSnackbar } from "notistack";

const { Panel } = Collapse;

const SubSchedule = ({ schedule }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isCreate, setIsCreate] = useState(false);
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const [listTimeSlot, setListTimeSlot] = useState([]);
  const [listSubTimeslot, setListSubTimeslot] = useState([]);
  const currentRoute = useAppSelector((state) => state.routeState.currentRoute);
  const [changeName, setChangeName] = useState(false);
  const [scheduleName, setScheduleName] = useState("Lịch phụ");
  const [currentTimeslot, setCurrentTimeslot] = useState();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const chooseCoach = useAppSelector((state) => state.routeState.chooseCoach);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);

  useEffect(() => {
    setListTimeSlot(listSubTimeslot);
    setScheduleName(schedule?.name);
    // listSubTimeslot.length ? setCurrentTimeslot(listSubTimeslot[0]) : null;
    // listSubTimeslot.length ? setCurrentIndex(0) : null;
  }, [schedule]);

  const handleCreateSubSchedule = async (e) => {
    e.preventDefault();
    const tmp = listSubTimeslot.map((timeslot) => {
      const coach = listCoach.find((coach) => coach.id == timeslot.coachId);
      const coachId = coach?.coachType.id;
      const tmp_1 = timeslot.sectionList?.map((section) => {
        return {
          ...section,
          price: section.price ? section.price : section.priceMap[coachId],
          templateId: section.id,
        };
      });
      return {
        ...timeslot,
        price: deleteDot(timeslot.price),
        sectionList: tmp_1,
      };
    });
    const data = {
      coachRouteId: currentRoute,
      startTime: firstDate,
      endTime: secondDate,
      date: 0,
      type: 1,
      createTimeslotRequestList: tmp,
      name: scheduleName,
    };
    const res = await apiCreateSubSchedule(data);
    if (!res.data.error) {
      enqueueSnackbar("Tạo lịch phụ thành công", { variant: "success" });
      setListTimeSlot(tmp);
    } else {
      enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
    }
  };

  const handleCreateTimeslot = async () => {
    let tmp = listSubTimeslot.length - 1;
    const coach = listCoach.find(
      (coach) => coach.id == listTimeSlot[tmp].coachId
    );
    const coachId = coach?.coachType.id;
    const sectionList = listSubTimeslot[tmp].sectionList.map((section) => {
      return {
        ...section,
        price: section.priceMap[coachId],
        templateId: section.id,
      };
    });
    const data = {
      ...listSubTimeslot[tmp],
      coachScheduleId: listSubTimeslot[0].coachSchedule.id,
      sectionList: sectionList,
      coachRouteId: currentRoute,
      type: 0,
    };
    const res = await apiCreateTimeslot(data);
    if (!res.data.error) {
      setListSubTimeslot(listSubTimeslot);
    }
  };

  const handleDelSchedule = async () => {
    const res = await apiDeleteSubSchedule({
      coachRouteId: currentRoute,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
    });
    if (!res.data.error) {
      setListSubTimeslot([]);
    }
  };
  if (!schedule) return;

  return (
    <div>
      <div className="mt-4 space-y-4">
        <div className="flex flex-row space-y-4 justify-between items-center">
          <Row className="space-x-2 items-center">
            {changeName ? (
              <Input
                onChange={(e) => setScheduleName(e.target.value)}
                className="w-48"
                placeholder="Tên lịch phụ"
              />
            ) : (
              <Title level={3}>{schedule?.name ?? "Lịch phụ"}</Title>
            )}
            <div
              onClick={() => setChangeName(!changeName)}
              className="text-green-900"
            >
              <EditFilled /> Sửa tên lịch phụ
            </div>
          </Row>

          <Row className="items-center space-x-6">
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: 200 }}
              value={schedule.startTime ? dayjs(schedule.startTime) : undefined}
              onChange={(date) => setFirstDate(date.startOf("day").valueOf())}
            />
            <ArrowRightOutlined />
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: 200 }}
              value={schedule.endTime ? dayjs(schedule.endTime) : undefined}
              onChange={(date) => setSecondDate(date.startOf("day").valueOf())}
            />
          </Row>
          {!schedule?.coachScheduleList?.length > 0 && (
            <Button
              style={{
                backgroundColor: "white",
                color: "#006D38",
                borderRadius: 4,
                marginTop: 10,
              }}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setCurrentIndex(listSubTimeslot.length);
                setListSubTimeslot([...listSubTimeslot, {}]);
                setCurrentTimeslot({});
                setIsCreate(true);
                dispatch(setChooseCoach(undefined));
              }}
            >
              Thêm giờ
            </Button>
          )}
        </div>
        <Collapse>
          {schedule?.coachScheduleList?.map((item, index) => (
            <Panel
              header={`${dayjs(new Date(item.date)).format("DD/MM/YYYY")}`}
              key={index}
              onClick={() => {
                setListSubTimeslot(item.timeslotList);
                // setCurrentTimeslot(item.timeslotList[0]);
                // setCurrentIndex(0);
              }}
            >
              <div className="z-50 space-y-2">
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#006D38",
                    borderRadius: 4,
                    marginTop: 10,
                  }}
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    setCurrentIndex(listSubTimeslot.length);
                    setListSubTimeslot([...listSubTimeslot, {}]);
                    setCurrentTimeslot({});
                    setIsCreate(true);
                    dispatch(setChooseCoach(undefined));
                  }}
                >
                  Thêm giờ
                </Button>
                <div className="flex flex-row space-x-2">
                  {listSubTimeslot?.map((sh, index) => (
                    <Button
                      key={index}
                      onClick={() => {
                        setCurrentTimeslot(sh);
                        setCurrentIndex(index);
                      }}
                      className={index != currentIndex ? "un-choose-btn" : ""}
                    >
                      {sh.departureTime || sh.departureTime == 0
                        ? dayjs(convertSecondsToDayjs(sh.departureTime)).format(
                            "HH:mm"
                          )
                        : "--:--"}
                    </Button>
                  ))}
                </div>

                {currentTimeslot && (
                  <TimeSlotCardV2
                    schedule={currentTimeslot}
                    setCurrentTimeslot={setCurrentTimeslot}
                    index={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    listTimeSlot={listSubTimeslot}
                    setListTimeSlot={setListSubTimeslot}
                    // limit={{
                    //   from: currentTimeslot.coachSchedule.startTime ?? startTime,
                    //   to: currentTimeslot.coachSchedule.endTime ?? endTime,
                    // }}
                  />
                )}
              </div>
            </Panel>
          ))}
        </Collapse>

        {/* <Row className="justify-center">
          {!currentTimeslot?.id && (
            <Button
              onClick={(e) =>
                listSubTimeslot[0]?.id
                  ? handleCreateTimeslot()
                  : handleCreateSubSchedule(e)
              }
            >
              Hoàn thành
            </Button>
          )}
        </Row> */}
        <Divider />
      </div>
    </div>
  );
};

export default SubSchedule;
