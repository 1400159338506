import EndPoint from "../common/endpoints";
import { ApiAdminConfig, ApiConfig } from "./config";

export const apiLogin = async (payload) => {
  return ApiConfig(EndPoint.LOGIN, payload);
};

//forget-password

export const apiForgetPassword = async (payload) => {
  return ApiConfig("/auth/forget-password", payload);
}

//change--password

export const apiChangePassword = async (payload) => {
  return ApiConfig("/auth/change-password", payload);
}

//admin
export const apiGetListNews = async () => {
  return ApiConfig(EndPoint.LIST_NEWS, undefined, "GET");
};

export const apiCreateNewsFeed = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_NEWS, props);
};

export const apiCreateMediaContent = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_MEDIA, props);
};

export const apiGetMediaContent = async (type) => {
  return ApiConfig(
    `${EndPoint.LIST_MEDIA_CONTENT}?type=${type}`,
    undefined,
    "GET"
  );
};

export const apiGetListCompany = async () => {
  return ApiAdminConfig(EndPoint.LIST_COMPANY, undefined, "GET");
};

export const apiCreateCompany = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_COMPANY, props);
};

//global
export const apiGetListProvince = async () => {
  return ApiConfig(EndPoint.LIST_PROVINCE, undefined, "GET");
};

export const apiGetListDistrict = async (p) => {
  return ApiConfig(
    `${EndPoint.LIST_DISTRICT}?provinceId=${p}`,
    undefined,
    "GET"
  );
};

export const apiGetLocation = async (p) => {
  return ApiConfig(`/global/get-location?locationId=${p}`, undefined, "GET");
};

export const apiGetCoaches = async () => {
  return ApiConfig("/global/get-coaches", undefined, "GET");
};

//manage

export const apiCreateStaff = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_STAFF, props);
};

export const apiGetStaff = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.LIST_STAFF}?companyId=${id}`,
    undefined,
    "GET"
  );
};

export const apiUpdateStaff = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_STAFF, props);
};

export const apiDelStaff = async (props) => {
  return ApiAdminConfig(EndPoint.DEL_STAFF, props);
};

export const apiUpdateCompany = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_COMPANY, props);
};

export const apiGetCompanyInfo = async () => {
  return ApiAdminConfig(EndPoint.GET_COMPANY_INFO, undefined, "GET");
};

export const apiUpdateCompanyInfo = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_COMPANY_INFO, props);
};

export const apiCreateCoachRoute = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_ROUTE, props);
};

export const apiGetListRoute = async (companyId) => {
  return ApiAdminConfig(
    `${EndPoint.LIST_ROUTE}?companyId=${companyId}`,
    undefined,
    "GET"
  );
};

export const apiAddPointToRoute = async (props) => {
  return ApiAdminConfig(EndPoint.ADD_POINT_TO_ROUTE, props);
};

export const apiGetPointList = async (companyId) => {
  return ApiAdminConfig(
    `${EndPoint.GET_LIST_POINT}?companyId=${companyId}`,
    undefined,
    "GET"
  );
};

export const apiCreateRouteTemplate = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_ROUTE_TEMPLATE, props);
};

export const apiGetRouteTemplate = async (coachRouteId) => {
  return ApiAdminConfig(
    `${EndPoint.GET_ROUTE_TEMPLATE}?coachRouteId=${coachRouteId}`,
    undefined,
    "GET"
  );
};

export const apiUpdateRouteTempalte = async (props) => {
  return ApiAdminConfig("/company/update-route-template", props);
}

export const apiGetRouteDetail = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.GET_ROUTE_DETAIL}?coachRouteId=${id}`,
    undefined,
    "GET"
  );
};

export const apiDeleteRoute = async (props) => {
  return ApiAdminConfig(EndPoint.DELETE_ROUTE, props);
};

export const apiCreateOffice = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_OFFICE, props);
};

export const apiListOffice = async (companyId) => {
  return ApiAdminConfig(
    `${EndPoint.LIST_OFFICE}?companyId=${companyId}`,
    undefined,
    "GET"
  );
};

export const apiOfficeInDistrict = async ({ companyId, districtId }) => {
  return ApiAdminConfig(
    `${EndPoint.OFFICE_IN_DISTRICT}?companyId=${companyId}&locationId=${districtId}`,
    undefined,
    "GET"
  );
};

export const apiOfficeInProvince = async ({ companyId, locationId }) => {
  return ApiAdminConfig(
    `/global/get-company-office-list-in-province?companyId=${companyId}&locationId=${locationId}`,
    undefined,
    "GET"
  );
};

export const apiDeleteOffice = async (props) => {
  return ApiAdminConfig(EndPoint.DEL_OFFICE, props);
};

export const apiUpdateOffice = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_OFFICE, props);
};

export const apiCreateCoach = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_COACH, props);
};

export const apiGetCoachList = async (id) => {
  return ApiAdminConfig(
    `/company/get-coach-list?coachCompanyId=${id}`,
    undefined,
    "GET"
  );
};

export const apiDelCoach = async (props) => {
  return ApiAdminConfig(EndPoint.DEL_COACH, props);
};

export const apiUpdateCoach = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_COACH, props);
};

export const apiCreateSchedule = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_SCHEDULE, props);
};

export const apiListSchedule = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.LIST_SCHEDULE}?coachRouteId=${id}`,
    undefined,
    "GET"
  );
};

export const apiDeleteSchedule = async (props) => {
  return ApiAdminConfig("/company/delete-coach-schedule", props);
};

export const apiDeleteSubSchedule = async (props) => {
  return ApiAdminConfig("/company/delete-sub-coach-schedule", props);
};

export const apiCreateSubSchedule = async (props) => {
  return ApiAdminConfig("/company/create-sub-coach-schedule", props);
}

export const apiGetSubSchedule = async (props) => {
  const { coachRouteId, date } = props;
  return ApiAdminConfig(`/company/get-sub-schedule-list?coachRouteId=${coachRouteId}&date=${date}`, undefined, "GET");
}

export const apiCreateTimeslot = async (props) => {
  return ApiAdminConfig("/company/create-timeslot", props);
};

export const apiCreateBoosterTimeslot = async (props) => {
  return ApiAdminConfig("/company/create-booster-timeslot", props)
}

export const apiGetListTimeslotByDate = async (props) => {
  const { date, coachRouteId } = props;
  return ApiAdminConfig(
    `/company/get-timeslot-list-by-date?coachRouteId=${coachRouteId}&date=${date}`,
    undefined,
    "GET"
  );
};

export const apiCreateSection = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_SECTION, props);
};

export const apiGetSection = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.LIST_SECTION}?timeslotId=${id}`,
    undefined,
    "GET"
  );
};

export const apiGetTimeSlot = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.LIST_TIMESLOT}?coachScheduleId=${id}`,
    undefined,
    "GET"
  );
};

export const apiUpdateTimeslot = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_TIMESLOT, props);
};

export const apiUpdateMulti = async (props) => {
  return ApiAdminConfig("/company/update-multi-timeslot", props);
};

export const apiCreateMulti = async (props) => {
  return ApiAdminConfig("/company/create-multi-timeslot", props);
};

export const apiDeleteTimeslot = async (props) => {
  return ApiAdminConfig("/company/delete-timeslot", props);
};

export const apiCreateTravelPath = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_TP, props);
};

export const apiGetTravelPathList = async (id) => {
  return ApiAdminConfig(`${EndPoint.GET_TP}?companyId=${id}`, undefined, "GET");
};

export const apiDelTravelPath = async (id) => {
  return ApiAdminConfig(EndPoint.DEL_TP, { id: id });
};

export const apiCreatePolicy = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_POLICY, props);
};

export const apiGetPolicyList = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.GET_POLICY}?companyId=${id}`,
    undefined,
    "GET"
  );
};

export const apiUpdatePolicy = async(props) => {
  return ApiAdminConfig("/company/update-policy", props);
}

export const apiDelPolicy = async (props) => {
  return ApiAdminConfig(EndPoint.DEL_POLICY, props);
};

export const apiGetListOrder = async (props) => {
  return ApiAdminConfig(
    `/company/get-all-user-order-list?companyId=${props}`,
    undefined,
    "GET"
  );
};

export const apiChangeOrderState = async (props) => {
  return ApiAdminConfig("/company/change-user-order-state", props);
};

export const apiGetStatistic = async (id) => {
  return ApiAdminConfig(
    `/company/get-statistic?companyId=${id}`,
    undefined,
    "GET"
  );
};

//seat-diagram

export const apiCreateSeatDiagram = async (props) => {
  return ApiAdminConfig(EndPoint.CREATE_SEAT_DIAGRAM, props);
};

export const apiUpdateSeatDiagram = async (props) => {
  return ApiAdminConfig("/company/update-seat-diagram", props);
}

export const apiGetSeatDiagram = async (props) => {
  const { companyId, coachTypeId } = props;
  return ApiAdminConfig(
    `/company/get-seat-diagram-list?coachTypeId=${coachTypeId}&companyId=${companyId}`,
    undefined,
    "GET"
  );
};

//ticket

export const apiGetSeatList = async (props) => {
  const { coachVsc, timeslotDepartureTime, companyId } = props;
  return ApiAdminConfig(
    `${EndPoint.GET_SEAT_LIST}?coachVsc=${coachVsc}&companyId=${companyId}&timeslotDepartureTime=${timeslotDepartureTime}`,
    undefined,
    "GET"
  );
};

export const apiCreateSeatOrder = async (props) => {
  return ApiAdminConfig(EndPoint.ORDER_SEAT, props);
};

export const apiGetDropOffPoint = async (props) => {
  const { pickUpPointId, timeslotId } = props;
  return ApiAdminConfig(
    `${EndPoint.GET_DROP_OFF_POINT_IN_TIMESLOT}?timeslotId=${timeslotId}&pickUpPointId=${pickUpPointId}`,
    undefined,
    "GET"
  );
};

export const apiGetPickUpPoint = async (props) => {
  const { dropOffPointId, timeslotId } = props;
  return ApiAdminConfig(
    `${EndPoint.GET_PICK_UP_POINT_IN_TIMESLOT}?timeslotId=${timeslotId}&dropOffPointId=${dropOffPointId}`,
    undefined,
    "GET"
  );
};

export const apiFindCoachOnTimeslot = async (props) => {
  return ApiAdminConfig(EndPoint.FIND_COACH_ON_TIMESLOT, props);
};

export const apiLockSeat = async (props) => {
  return ApiAdminConfig(EndPoint.LOCK_SEAT, props);
};

export const apiGetOrderById = async (id) => {
  return ApiAdminConfig(
    `${EndPoint.GET_ORDER_BY_ID}?orderId=${id}`,
    undefined,
    "GET"
  );
};

export const apiUpdateSeatOrder = async (props) => {
  return ApiAdminConfig(EndPoint.UPDATE_SEAT_ORDER, props);
};

export const apiFindCoaches = async (props) => {
  return ApiAdminConfig(EndPoint.FIND_COACH, props);
};

export const apiGetPickUpPointList = async (props) => {
  const { companyId, dropOffPointId } = props;
  return ApiAdminConfig(
    `${EndPoint.GET_PICK_UP_LIST}?companyId=${companyId}&dropOffPointId=${dropOffPointId}`,
    undefined,
    "GET"
  );
};

export const apiGetDropOffPointList = async (props) => {
  const { companyId, pickUpPointId } = props;
  return ApiAdminConfig(
    `${EndPoint.GET_DROP_OFF_LIST}?companyId=${companyId}&pickUpPointId=${pickUpPointId}`,
    undefined,
    "GET"
  );
};

export const apiTurnOnOffTimeslot = async (props) => {
  return ApiAdminConfig("/company/change-status-timeslot", props);
}

export const apiTurnOnOffSubTimeslot = async (props) => {
  return ApiAdminConfig("/company/change-status-timeslot-of-sub-schedule", props);
}

export const apiCreateSeatPriceDiagram = async (props) => {
  return ApiAdminConfig("/company/create-seat-price-diagram", props);
}

export const apiUpdateSeatPriceDiagram = async (props) => {
  return ApiAdminConfig("/company/update-seat-price-diagram", props);
}

export const apiGetSeatPriceDiagram = async (props) => {
  const { coachRouteId, coachTypeId } = props;
  return ApiAdminConfig(`/company/get-seat-price-diagram?coachRouteId=${coachRouteId}&coachTypeId=${coachTypeId}`, undefined, "GET");
}

export const apiCreateSeatConfig = async (props) => {
  return ApiAdminConfig("/company/create-seat-config", props);
}

export const apiGetSeatConfig = async (props) => {
  const { coachRouteId, coachTypeId } = props;
  return ApiAdminConfig(`/company/get-seat-config?coachRouteId=${coachRouteId}&coachTypeId=${coachTypeId}`, undefined, "GET");
}

export const apiUpdateSeatConfig = async(props) => {
  return ApiAdminConfig("/company/update-seat-config", props);
}