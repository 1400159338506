// import { apiChooseSeat, apiGetSeatList, apiUnChooseSeat } from "../../../../../api/services"
import { Button } from "antd";
import { LockIcon, Steering } from "../../../../../assets/svgs";

export const Seat = (props) => {
  const { id, num, state, setCurrentSeat } = props;

  if (state == 1)
    return (
      <div className="flex flex-col items-center">
        <Button
          className={`w-12 h-8 border-2 flex justify-center rounded-lg cursor-pointer`}
          onClick={() => setCurrentSeat(props)}
        >
          <LockIcon />
        </Button>
      </div>
    );

  const render = () => {
    switch (num) {
      case "0":
        return (
          <div className="flex flex-col items-center">
            <Steering />
          </div>
        );
      case null:
        return <div className="w-12 h-8 bg-transparent"></div>;
      case "WC":
        return (
          <div className="flex flex-col items-center">
            <Button
              className={`w-12 h-8 border-2 rounded-lg text-center cursor-pointer`}
              disabled
            >
              WC
            </Button>
          </div>
        );
      case num:
        return (
          <div className="flex flex-col items-center">
            <Button
              className={`w-12 h-8 border-2 rounded-lg text-center cursor-pointer`}
              style={{
                backgroundColor:
                  LIST_COLOR[Math.floor(Math.random() * LIST_COLOR.length)],
              }}
              onClick={() => setCurrentSeat(props)}
            >
              {num}
            </Button>
          </div>
        );
    }
  };

  return render();
};

const LIST_COLOR = ["#7ED7C1", "#FFC5C5", "#C0DBEA"];
