import { useEffect } from "react";
import RouteCard from "./components/RouteCard";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { requestLoadRouteTemplate } from "../../../../../redux/slices/routeSlice";

const RouteTemplate = ({ routeId }) => {
  const dispatch = useAppDispatch();
  // const currentRoute = useAppSelector(state => state.routeState.currentRoute)
  // useEffect(() => {
  //     dispatch(requestLoadRouteTemplate(routeId))
  // }, [routeId])

  return (
    <div className="mt-6 bg-white">
      <RouteCard routeId={routeId} />
    </div>
  );
};

export default RouteTemplate;
