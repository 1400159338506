import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { Form, Input, Button, Checkbox, Row } from "antd";
import { MailFilled, LockFilled } from "@ant-design/icons";
import background from "../../../assets/background-login.png";
import logo from "../../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { apiChangePassword, apiForgetPassword } from "../../../api/services";
import { enqueueSnackbar } from "notistack";

export const ForgetPasswordScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const [state, setState] = useState(0);
  const [email, setEmail] = useState("");

  const handleInputEmail = async (data) => {
    try {
      const res = await apiForgetPassword(data);
      if (!res.data.error) {
        setState(1);
        setEmail(data.email);
        enqueueSnackbar("Mã OTP đã được gửi đến email của bạn", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Email không tồn tại", {
          variant: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePassword = async (data) => {
    try {
      if (data.password != data.reEntryPassword) {
        enqueueSnackbar("Mật khẩu không trùng khớp", {
          variant: "error",
        });
        return;
      }
      console.log({ ...data, email });
      const res = await apiChangePassword({ ...data, email });
      if(!res.data.error) {
        enqueueSnackbar("Đổi mật khẩu thành công", {
          variant: "success",
        });
        navigate("/login");
      } else {
        enqueueSnackbar("Mã OTP không đúng", {
          variant: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex">
      <div className="w-3/4 h-screen items-center justify-center mobile:hidden desktop:flex">
        <img
          src={background}
          alt="background"
          className="max-h-full max-w-full"
        />
      </div>

      <div className="w-1/4 p-4 flex flex-col items-center justify-center h-screen mobile:w-full desktop:flex">
        {state ? (
          <div>
            <img src={logo} alt="logo" height="100vh" />
            <b className="text-2xl">Thay đổi mật khẩu</b>
            <Form
              initialValues={{ remember: true }}
              onFinish={handleChangePassword}
              autoComplete="off"
            >
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mã OTP",
                  },
                ]}
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập OTP..."
                  prefix={<MailFilled />}
                  className="w-80 p-2 border rounded-xl mt-4"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu",
                  },
                ]}
              >
                <Input.Password
                  ref={inputRef}
                  placeholder="Nhập mật khẩu mới"
                  prefix={<LockFilled />}
                  className="w-80 border rounded-xl"
                />
              </Form.Item>
              <Form.Item
                name="reEntryPassword"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập lại mật khẩu",
                  },
                ]}
              >
                <Input.Password
                  ref={inputRef}
                  placeholder="Nhập lại mật khẩu "
                  prefix={<LockFilled />}
                  className="w-80 border rounded-xl"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  className="w-80 h-10 bg-green-700 hover:bg-white text-white font-extrabold border rounded-xl mt-4"
                >
                  Xác nhận
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div>
            <img src={logo} alt="logo" height="100vh" />
            <b className="text-2xl">Nhập email đăng ký tài khoản</b>
            <Form
              initialValues={{ remember: true }}
              onFinish={handleInputEmail}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập email đăng ký tài khoản!",
                  },
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập email..."
                  prefix={<MailFilled />}
                  className="w-80 p-2 border rounded-xl mt-4"
                />
              </Form.Item>

              {/* <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                ref={inputRef}
                onFocus={handleFocus}
                placeholder="Mật khẩu"
                prefix={<LockFilled />}
                className="w-80 border rounded-xl"
              />
            </Form.Item>
            <Row>
              <Checkbox>Ghi nhớ đăng nhập</Checkbox>
              <a className="text-green-700 font-bold ml-12">Quên mật khẩu?</a>
            </Row> */}

              <Form.Item>
                <Button
                  htmlType="submit"
                  className="w-80 h-10 bg-green-700 hover:bg-white text-white font-extrabold border rounded-xl mt-4"
                >
                  Gửi OTP
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};
