import logo from "../../../../assets/logo.png";
import AppNav from "../AppNav";

const Header = () => {
  return (
    <div className="h-20 grid grid-cols-12 gap-20 items-center justify-center px-8">
      <div className="flex flex-row items-center col-span-3">
        <img src={logo} alt="logo" />
      </div>
      <div className="flex flex-row items-center justify-center col-span-9 bg-green-700 rounded-2xl h-12 mt-4">
        <AppNav />
      </div>
    </div>
  );
};

export default Header;
