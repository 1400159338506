import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Select,
  Card,
  Button,
  Input,
  List,
  Typography,
  Checkbox,
  Tooltip,
} from "antd";
import {
  ArrowLeftOutlined,
  SearchOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
  apiAddPointToRoute,
  apiGetListDistrict,
  apiGetLocation,
  apiOfficeInProvince,
} from "../../../../../api/services";
import "./style.css";
import LoadingPage from "../../../../../utils/Loading";
import { requestLoadProvince } from "../../../../../redux/slices/globalSlice";
import { requestLoadListPoint } from "../../../../../redux/slices/companySlice";
import { removeVietnameseTones } from "../../../../../utils/convertTime";
const { Title } = Typography;

const AddPointToRoute = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const currentRoute = useAppSelector((state) => state.routeState.currentRoute);
  const listProvince = useAppSelector(
    (state) => state.globalState.listProvince
  );
  const listRoute = useAppSelector((state) => state.routeState.listRoute);
  const isLoading = useAppSelector((state) => state.companyState.loading);
  const currentListPoint = useAppSelector(
    (state) => state.companyState.listPoint
  );
  const [listDistrict, setListDistrict] = useState([]);
  const [listPoint, setListPoint] = useState([]);
  const [showP, setShowP] = useState(listProvince);
  const [showD, setShowD] = useState(false);
  const [delPoint, setDelPoint] = useState([]);
  const [pickPoint, setPickPoint] = useState([]);
  const [mapOffice, setMapOffice] = useState({});
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    dispatch(requestLoadListPoint(companyId));
    dispatch(requestLoadProvince());
    setListPoint(currentListPoint);
    handleLoadPoint();
    setDelPoint([]);
    setPickPoint(currentListPoint.map((point) => point.locationId));
  }, []);

  async function loadDistrict(value) {
    if (value) {
      const res = await apiGetListDistrict(value);
      const listD = res.data.data.map((d) => ({
        value: d.id,
        label: d.district,
      }));
      setListDistrict(listD);
    }
  }

  async function handleChooseDistrict(value) {
    setListPoint(listPoint.filter((point) => point.locationId !== value));
    if(pickPoint.includes(value)) {
      setPickPoint(pickPoint.filter((point) => point !== value));
    } else {
      setPickPoint([...pickPoint, value]);
    }
    let check = 0;
    listPoint.map((point) => {
      if (point.locationId === value) check = 1;
    });
    let officeIndex = {};
    const res = await apiGetLocation(value);
    const tmp = {
      address: `${res.data.data.district} / ${res.data.data.province}`,
      locationId: value,
      location: {
        provinceId: res.data.data.provinceId,
      },
    };
    const office = await apiOfficeInProvince({
      companyId: companyId,
      locationId: res.data.data.provinceId,
    });
    officeIndex[res.data.data.provinceId] = office.data.data.map((of) => ({
      value: of.id,
      label: of.name,
    }));
    setMapOffice({ ...mapOffice, ...officeIndex });
    if (isEdit && !check) {
      setListPoint([...listPoint, tmp]);
    }
  }

  async function handleLoadPoint() {
    const listProvince = currentListPoint.map(
      (point) => point.location.provinceId
    );
    const uniqueProvince = [...new Set(listProvince)];
    // await Promise.all(
    uniqueProvince.map(async (point) => {
      let officeIndex = {};
      let office = await apiOfficeInProvince({
        companyId: companyId,
        locationId: point,
      });
      officeIndex[point] = office.data.data.map((of) => ({
        value: of.id,
        label: of.name,
      }));
      setMapOffice((prev) => ({ ...prev, ...officeIndex }));
    });
    // )
  }

  async function handleAddPoint(listPoint) {
    // const tmp = listPoint.map((point, index) => ({
    //     ...point,
    //     sequence: index+1
    // }))
    const res = await apiAddPointToRoute({
      coachCompanyId: companyId,
      pointList: listPoint,
    });
    if (!res.data.error) {
      setIsEdit(false);
      dispatch(requestLoadListPoint(companyId));
      handleLoadPoint();
    }
  }

  async function handleDelPoint(listPoint) {
    const tmp = listPoint.filter((item) => !delPoint.includes(item.locationId));
    setListPoint([...tmp]);
    setPickPoint([]);
  }

  const onSearch = (e) => {
    const value = e.target.value;
    if (value == "") {
      setShowP(listProvince);
    } else {
      const tmp = listProvince.filter((e) => removeVietnameseTones(e.label.toLowerCase()).includes(value));
      setShowP(tmp);
    }
  };

  const handleChecked = (e, point) => {
    if (e.target.checked) {
      setDelPoint([...delPoint, point.locationId]);
    } else {
      const tmp = delPoint.filter((item) => item != point.locationId);
      setDelPoint([...tmp]);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="flex flex-row mt-6 space-x-4">
          <div className="w-1/4">
            <Card
              style={{
                height: "75vh",
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <InfiniteScroll dataLength={10}>
                {!showD ? (
                  <div>
                    <Title level={4}>Tỉnh / Thành</Title>
                    <Input
                      onChange={onSearch}
                      placeholder="Tìm kiếm"
                      allowClear
                      prefix={<SearchOutlined />}
                    />

                    <List
                      dataSource={showP}
                      renderItem={(item) => (
                        <List.Item
                          key={item.value}
                          onClick={() => {
                            if (isEdit) {
                              setShowD(true);
                              loadDistrict(item.value);
                            }
                          }}
                        >
                          <div className="cursor-pointer">{item.label}</div>
                        </List.Item>
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <ArrowLeftOutlined onClick={() => setShowD(false)} />
                    <List
                      dataSource={listDistrict}
                      renderItem={(item) => (
                        <List.Item
                          key={item.value}
                          onClick={() => {
                            handleChooseDistrict(item.value);
                          }}
                          className={`${
                            pickPoint.includes(item.value)
                              ? "bg-neutral-200"
                              : ""
                          }`}
                        >
                          <div className="grid grid-cols-6 w-full">
                            <div className="cursor-pointer col-span-5">
                              {item.label}
                            </div>
                            <div
                              className={`flex justify-end col-span-1 ${
                                pickPoint.includes(item.value)
                                  ? "opacity-100"
                                  : "opacity-0"
                              }`}
                            >
                              <CheckOutlined style={{ color: "green" }} />
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                )}
              </InfiniteScroll>
            </Card>
          </div>
          <div className="w-3/4">
            <Card
              style={{
                height: "75vh",
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
              className="relative"
            >
              <div>
                {listPoint.length ? (
                  <div className="space-y-3 h-[60vh] overflow-y-scroll">
                    {listPoint.map((point, index) => {
                      return (
                        <div
                          className="flex-row grid grid-cols-3"
                          key={index}
                          // draggable={isEdit}
                          // onDragStart={(e) => (dragItem.current = index)}
                          // onDragEnter={(e) => (dragOverItem.current = index)}
                          // onDragEnd={handleSort}
                          // onDragOver={(e) => e.preventDefault()}
                        >
                          <div className="space-x-2 col-span-1">
                            <Checkbox
                              checked={delPoint.includes(point.locationId)}
                              onChange={(e) => handleChecked(e, point)}
                              disabled={!isEdit}
                            />
                            <b className="cursor-default">{point.address} :</b>
                          </div>
                          <div
                            className="flex flex-row space-x-2 justify-end col-span-2"
                            draggable={true}
                            onDragStart={(e) => e.preventDefault()}
                          >
                            {!index ? (
                              <Tooltip title="Mô tả nội dung đón / trả khách của điểm này.">
                                <Input
                                  onChange={(e) => {
                                    const tmp = {
                                      ...listPoint[index],
                                      description: e.target.value,
                                    };
                                    setListPoint([
                                      ...listPoint.slice(0, index),
                                      tmp,
                                      ...listPoint.slice(index + 1),
                                    ]);
                                    // listPoint[index] = tmp
                                  }}
                                  defaultValue={point.description}
                                  className="w-1/2"
                                  disabled={!isEdit}
                                />
                              </Tooltip>
                            ) : (
                              <Input
                                onChange={(e) => {
                                  const tmp = {
                                    ...listPoint[index],
                                    description: e.target.value,
                                  };
                                  setListPoint([
                                    ...listPoint.slice(0, index),
                                    tmp,
                                    ...listPoint.slice(index + 1),
                                  ]);
                                  // listPoint[index] = tmp
                                }}
                                defaultValue={point.description}
                                className="w-1/2"
                                disabled={!isEdit}
                              />
                            )}

                            <p>+</p>
                            {!index ? (
                              <Tooltip title="Chọn các văn phòng tại điểm này nếu có hoặc muốn khách tự đến văn phòng.">
                                <Select
                                  className="w-1/2"
                                  onChange={(value) => {
                                    const tmp = {
                                      ...listPoint[index],
                                      officeIdList: value,
                                    };
                                    setListPoint([
                                      ...listPoint.slice(0, index),
                                      tmp,
                                      ...listPoint.slice(index + 1),
                                    ]);
                                  }}
                                  mode="multiple"
                                  defaultValue={point.officeIdList}
                                  disabled={!isEdit}
                                  options={mapOffice[point.location.provinceId]}
                                ></Select>
                              </Tooltip>
                            ) : (
                              <Select
                                className="w-1/2"
                                onChange={(value) => {
                                  const tmp = {
                                    ...listPoint[index],
                                    officeIdList: value,
                                  };
                                  setListPoint([
                                    ...listPoint.slice(0, index),
                                    tmp,
                                    ...listPoint.slice(index + 1),
                                  ]);
                                }}
                                mode="multiple"
                                defaultValue={point.officeIdList}
                                disabled={!isEdit}
                                options={mapOffice[point.location.provinceId]}
                              ></Select>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className="grid grid-cols-2 sticky bottom-8">
                  <div>
                    {listPoint.length ? (
                      <p className="mt-10">
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDelPoint(
                                listPoint.map((point) => point.locationId)
                              );
                            } else {
                              setDelPoint([]);
                            }
                          }}
                          disabled={!isEdit}
                        />{" "}
                        Chọn tất cả
                      </p>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    {listPoint.length | isEdit ? (
                      <div>
                        {isEdit && (
                          <Button
                            onClick={() => {
                              handleLoadPoint(currentRoute);
                              setIsEdit(!isEdit);
                              setPickPoint([]);
                            }}
                            className="mt-10 text-white pause-btn"
                          >
                            Hủy
                          </Button>
                        )}
                        {isEdit && (
                          <Button
                            onClick={() => handleDelPoint(listPoint)}
                            className="mt-10 text-white del-btn"
                          >
                            Xóa
                          </Button>
                        )}
                        {isEdit ? (
                          <Button
                            onClick={() => handleAddPoint(listPoint)}
                            className="mt-10 text-white"
                          >
                            Lưu
                          </Button>
                        ) : (
                          <Button
                            onClick={() => setIsEdit(true)}
                            className="mt-10 text-white"
                          >
                            Sửa
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPointToRoute;
