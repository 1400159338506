export default class EndPoint {
  static LOGIN = "/auth/login";
  //admin
  static LIST_COMPANY = "/admin/get-company-list";
  static CREATE_COMPANY = "/admin/create-company-account";
  static UPDATE_COMPANY = "/admin/update-company-account";
  static CREATE_MEDIA = "/admin/create-media-content";
  static CREATE_NEWS = "/admin/create-news-feed";
  //global
  static LIST_MEDIA_CONTENT = "/global/get-media-content";
  static LIST_NEWS = "/global/get-news-feed";
  static LIST_PROVINCE = "/global/get-provinces";
  static LIST_DISTRICT = "/global/get-districts";

  //company
  static GET_COMPANY_INFO = "/company/get-info";
  static UPDATE_COMPANY_INFO = "/company/update-coach-company";
  static CREATE_ROUTE = "/company/create-coach-route";
  static LIST_ROUTE = "/company/get-coach-route-list";
  static DELETE_ROUTE = "/company/delete-coach-route";
  static ADD_POINT_TO_ROUTE = "/company/add-point-to-route";
  static GET_LIST_POINT = "/company/get-point-list";
  static GET_ROUTE_DETAIL = "/company/get-coach-route-detail";
  static CREATE_OFFICE = "/company/create-company-office";
  static LIST_OFFICE = "/company/get-company-office-list";
  static UPDATE_OFFICE = "/company/update-company-office";
  static OFFICE_IN_DISTRICT = "/global/get-company-office-list-in-district";
  static DEL_OFFICE = "/company/delete-company-office";
  static CREATE_COACH = "/company/create-coach";
  static DEL_COACH = "/company/delete-coach";
  static UPDATE_COACH = "/company/update-coach";
  static CREATE_SCHEDULE = "/company/create-coach-schedule";
  static LIST_SCHEDULE = "/company/get-coach-schedule-list";
  static CREATE_SECTION = "/company/create-coach-schedule-section";
  static LIST_SECTION = "/company/get-coach-schedule-section-list";
  static LIST_TIMESLOT = "/company/get-timeslot-list";
  static UPDATE_TIMESLOT = "/company/update-timeslot";
  static CREATE_STAFF = "/company/create-employee";
  static LIST_STAFF = "/company/get-employee-list";
  static UPDATE_STAFF = "/company/update-employee";
  static DEL_STAFF = "/company/delete-employee";
  static CREATE_TP = "/company/create-travel-path";
  static GET_TP = "/company/get-travel-path-list";
  static DEL_TP = "/company/delete-travel-path";
  static CREATE_POLICY = "/company/create-policy";
  static GET_POLICY = "/company/get-policy-list";
  static DEL_POLICY = "/company/delete-policy";
  static CREATE_ROUTE_TEMPLATE = "/company/create-route-template";
  static GET_ROUTE_TEMPLATE = "/company/get-route-template";

  //SEAT
  static CREATE_SEAT_DIAGRAM = "/company/create-seat-diagram";

  //TICKET
  static GET_SEAT_LIST = "/company/get-seat-list";
  static ORDER_SEAT = "/company/create-seat-order";
  static UPDATE_SEAT_ORDER = "/company/update-seat-order";

  static GET_PICK_UP_LIST = "/company/get-pick-up-list";
  static GET_DROP_OFF_LIST = "/company/get-drop-off-list";

  //POINT
  static GET_DROP_OFF_POINT_IN_TIMESLOT =
    "/company/get-drop-off-list-on-timeslot";
  static GET_PICK_UP_POINT_IN_TIMESLOT =
    "/company/get-pick-up-list-on-timeslot";
  static FIND_COACH_ON_TIMESLOT = "/company/find-coach-on-timeslot";

  static FIND_COACH = "/company/find-coaches";

  static LOCK_SEAT = "/company/lock-seat";
  static GET_ORDER_BY_ID = "/company/get-order-by-id";
}
