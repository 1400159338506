import { secondToHour } from "../../../../../utils/convertTime";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import { chooseTimeslot } from "../../../../../redux/slices/ticketSlice";
import { useEffect, useState } from "react";
import { COACH_TYPE } from "../../../../../constant";
import { IconListTimeslot } from "../../../../../assets/svgs";
import ModalListTimeslot from "./ModalListTimeslot";

const ListTimeslot = () => {
  const listTimeslot = useAppSelector(
    (state) => state.ticketState.listTimeslot
  );
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="w-fit flex flex-col space-y-2">
      <ModalListTimeslot 
      listTimeslot={listTimeslot}
      modalShow={modalShow}
      setModalShow={setModalShow}
      />
      <div className="flex flex-row bg-white text-black border-2 justify-center py-1 cursor-pointer hover:bg-neutral-200 border-neutral-400 rounded-md min-w-28 space-x-2"
      onClick={() => setModalShow(true)}
      >
        <IconListTimeslot />
        <span>{listTimeslot.length}</span>
      </div>
      {listTimeslot
        ? listTimeslot.map((time, index) => (
            <Item key={index} timeslot={time} />
          ))
        : null}
    </div>
  );
};

export default ListTimeslot;

const Item = (props) => {
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const [nos, setNos] = useState(0)
  const [vsc, setVsc] = useState("");
  const { timeslot } = props;
  const dispatch = useAppDispatch();
  const onChoseTimeslot = () => {
    dispatch(chooseTimeslot(timeslot));
  };

  useEffect(() => {
    const coach = listCoach.find((coach) => coach.id == timeslot.coachId);
    const numOfSeat = COACH_TYPE[coach?.coachType.id]?.nos
    setVsc(coach?.vsc)
    setNos(numOfSeat)
  }, [timeslot])

  return (
    <div
      className="flex flex-col h-fit items-center border-2 border-neutral-400 rounded-md w-32"
      onClick={onChoseTimeslot}
    >
      <div className="bg-white flex w-full justify-center rounded-t-md cursor-pointer">
        {secondToHour(timeslot?.departureTime / 1000)}
      </div>
      <div className="bg-[#80B155] text-white flex flex-row w-full justify-center cursor-pointer gap-4 px-1">
        <span className="text-[12px]">{vsc}</span>
        <span className="text-[12px]">{`${timeslot?.totalOrders}/${nos}`}</span>
      </div>
    </div>
  );
};
