import { Modal, DatePicker, Button } from "antd";
import TimeSlotCardV2 from "./TimeslotCardV2";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import { useSnackbar } from "notistack";
import { setChooseCoach } from "../../../../../redux/slices/routeSlice";
import {
  apiCreateBoosterTimeslot,
  apiCreateTimeslot,
} from "../../../../../api/services";
import {
  convertSecondsToDayjs,
  deleteDot,
} from "../../../../../utils/convertTime";
import "moment-lunar";
import moment from "moment";
import "./style.css";

export const ModalTimeslot = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const { modalShow, setModalShow } = props;
  const [currentTimeslot, setCurrentTimeslot] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listTimeSlot, setListTimeSlot] = useState([]);
  const [applyDate, setApplyDate] = useState([]);
  const currentRoute = useAppSelector((state) => state.routeState.currentRoute);
  const currentCoach = useAppSelector((state) => state.routeState.chooseCoach);
  // const initData = useAppSelector(
  //   (state) => state.scheduleState.currentTimeslot
  // );

  const getArrayDate = (value) => {
    const arr = value.map((item) => dayjs(item).valueOf());
    setApplyDate(arr);
  };

  const handleCreateMulti = async () => {
    let checkPrice = true;
    let checkCoach = true;
    let checkTravelPath =true;
    let checkDate = true;
    listTimeSlot.map((timeslot) => {
      if (!timeslot.coachId) {
        checkCoach = false;
      }
      if (!timeslot.travelPathId) {
        checkTravelPath = false;
      }
      if (!applyDate.length) {
        checkDate = false;
      }
    });
    
    const tmp = listTimeSlot.map((timeslot) => {
      const coach = listCoach.find((coach) => coach.id == timeslot.coachId);
      const coachId = coach?.coachType.id;
      const tmp_1 = timeslot.sectionList?.map((section) => {
        return {
          ...section,
          price: section.price
            ? section.price
            : section?.priceMap
            ? section?.priceMap[coachId]
            : 0,
          secondPrice: section.secondPrice
            ? section.secondPrice
            : section?.secondPriceMap
            ? section?.secondPriceMap[coachId]
            : 0,
          templateId: section.id,
        };
      });
      return {
        ...timeslot,
        price: deleteDot(timeslot.price),
        sectionList: tmp_1,
      };
    });

    tmp.map((timeslot) => {
      timeslot.sectionList.map((section) => {
        if (!section.price) {
          checkPrice = false;
        }
      });
    });
    if (!checkPrice) {
      enqueueSnackbar("Chưa nhập giá vé", { variant: "error" });
      return;
    }
    if(!checkCoach) {
      enqueueSnackbar("Chưa đủ thông tin loại xe", { variant: "error" });
      return;
    }
    if(!checkTravelPath) {
      enqueueSnackbar("Chưa đủ thông tin lộ trình", { variant: "error" });
      return;
    }
    if(!checkDate) {
      enqueueSnackbar("Chưa chọn ngày áp dụng", { variant: "error" });
      return;
    }
    const data = {
      timeslotList: tmp,
      applyDates: applyDate,
      coachRouteId: currentRoute,
      type: 1,
    };
    const res = await apiCreateBoosterTimeslot(data);
    if (!res.data.error) {
      enqueueSnackbar("Tạo lịch thành công", { variant: "success" });
      setModalShow(false);
    } else {
      enqueueSnackbar("Tạo lịch thất bại", { variant: "error" });
    }
  };

  useEffect(() => {
    dispatch(setChooseCoach(0));
  }, []);

  return (
    <Modal
      onCancel={() => setModalShow(false)}
      open={modalShow}
      onOk={() => handleCreateMulti()}
      width={1200}
    >
      <div className="flex flex-row space-x-2 mb-4">
        <DatePicker
          popupClassName="calendar-popup"
          cellRender={(value) => (
            <div className={`relative h-6`}>
              <p className="top-0 left-0">{value.format("DD")}</p>
              <p
                className={`absolute text-[10px] -bottom-2 -right-0 ${
                  dayjs(
                    moment()
                      .year(value.$y)
                      .month(value.$M)
                      .date(value.$D)
                      .lunar()
                  ).$D == 1
                    ? "text-red-500 -right-1"
                    : "text-gray-400"
                }`}
              >
                {moment()
                  .year(value.$y)
                  .month(value.$M)
                  .date(value.$D)
                  .lunar()
                  .format(
                    dayjs(
                      moment()
                        .year(value.$y)
                        .month(value.$M)
                        .date(value.$D)
                        .lunar()
                    ).$D == 1
                      ? "D/M"
                      : "DD"
                  )}
              </p>
            </div>
          )}
          format={"DD/MM/YYYY"}
          style={{ width: 400 }}
          multiple={true}
          disabledDate={(current) =>
            current && current < dayjs().startOf("day")
          }
          onChange={(value, dateString) => {
            getArrayDate(value);
          }}
          placeholder="Chọn ngày áp dụng"
        ></DatePicker>
        <Button
          onClick={() => {
            setCurrentIndex(listTimeSlot.length);
            setCurrentTimeslot({});
            dispatch(setChooseCoach(0));
            setListTimeSlot([...listTimeSlot, {}]);
          }}
        >
          Thêm giờ
        </Button>
      </div>
      <div className="flex flex-row space-x-2">
        {listTimeSlot?.map((sh, index) => (
          <Button
            key={index}
            onClick={() => {
              setCurrentTimeslot(sh);
              setCurrentIndex(index);
            }}
            className={index != currentIndex ? "un-choose-btn" : ""}
          >
            {sh.departureTime || sh.departureTime == 0
              ? dayjs(convertSecondsToDayjs(sh.departureTime)).format("HH:mm")
              : "--:--"}
          </Button>
        ))}
      </div>
      {currentTimeslot && (
        <TimeSlotCardV2
          schedule={currentTimeslot}
          index={currentIndex}
          listTimeSlot={listTimeSlot}
          setListTimeSlot={setListTimeSlot}
          setCurrentTimeslot={setCurrentTimeslot}
          limit={{ from: 0, to: 0 }}
        />
      )}
    </Modal>
  );
};
