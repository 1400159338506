import TransportForm from "../components/TransportForm";
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { requestLoadCoach } from "../../../../redux/slices/companySlice";
import { apiGetCoaches } from "../../../../api/services";
import LoadingPage from "../../../../utils/Loading";
import { useEffect, useState } from "react";
import SeatNumber from "./components/SeatNumber";

const Transport = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector((state) => state.authState.userInfo.id);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const isLoading = useAppSelector((state) => state.companyState.loading);
  const [isCreate, setIsCreate] = useState(false);
  const [numberSeat, setNumberSeat] = useState(0);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    handleLoadCoach();
    handleLoadOptions();
  }, []);

  const handleLoadCoach = async () => {
    try {
      await dispatch(requestLoadCoach(id));
    } catch (err) {
      console.log(err);
    }
  };

  async function handleLoadOptions() {
    const res = await apiGetCoaches();
    if(!res.data.error) {
      const listCoach = res.data.data.map((coach) => ({
        label: coach.name,
        value: coach.id,
      }));
      setOptions(listCoach);
    }
  }
  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="space-y-4 mx-8">
          <div className="bg-white w-full px-6 py-4 rounded-md grid grid-cols-2 gap-2">
            <Button
              icon={<PlusOutlined />}
              className="w-full border rounded-md h-10"
              onClick={() => {
                setIsCreate(!isCreate);
                setNumberSeat(0);
              }}
            >
              Tạo thêm xe
            </Button>
            <Button
              icon={<PlusOutlined />}
              className="w-full border rounded-md h-10"
              onClick={() => setNumberSeat(1)}
            >
              Điền số ghế
            </Button>
          </div>
          {numberSeat ? (
            <SeatNumber />
          ) : (
            <div className="flex flex-col space-y-4">
              {isCreate && (
                <TransportForm setIsCreate={setIsCreate} options={options} />
              )}
              <div className="space-y-4">
                {listCoach.map((coach, index) => (
                  <TransportForm key={index} transport={coach} options={options} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Transport;
