import {
  apiDeleteRoute,
  apiGetListDistrict,
} from "../../../../../api/services";
import { useState } from "react";
import { Select, Card, Button, Popconfirm, Modal, Tooltip } from "antd";
import { SwapOutlined, DeleteFilled } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
  requestCreateRoute,
  requestLoadListRoute,
} from "../../../../../redux/slices/routeSlice";
import { IconRouteTemplate } from "../../../../../assets/svgs";
import "./style.css";
import { unwrapResult } from "@reduxjs/toolkit";
import RouteTemplate from "../RouteTemplate";

const ModalRoute = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const listRoute = useAppSelector((state) => state.routeState.listRoute);
  const listProvince = useAppSelector(
    (state) => state.globalState.listProvince
  );
  const [currentRoute, setCurrentRoute] = useState();
  const [isTemplate, setIsTemplate] = useState(false);
  const [listSecondDistrict, setListSecondDistrict] = useState([]);
  const [listFirstDistrict, setListFirstDistrict] = useState([]);
  const [firstDistrict, setFirstDistrict] = useState();
  const [secondDistrict, setSecondDistrict] = useState();
  const [modalShow, setModalShow] = useState(false);

  const optionsListRoute = listRoute?.map((route) => ({
    value: route.id,
    label: `${route.startPoint.district}/${route.startPoint.province} - ${route.endPoint.district}/${route.endPoint.province}`,
  }));

  async function loadFirstDistrict(value) {
    if (value) {
      const res = await apiGetListDistrict(value);
      const listD = res.data.data.map((d) => ({
        value: d.id,
        label: d.district,
      }));
      setListFirstDistrict(listD);
    }
  }

  async function loadSecondDistrict(value) {
    if (value) {
      const res = await apiGetListDistrict(value);
      const listD = res.data.data.map((d) => ({
        value: d.id,
        label: d.district,
      }));
      setListSecondDistrict(listD);
    }
  }
  async function handleLoadRoutes(companyId) {
    try {
      await dispatch(requestLoadListRoute(companyId));
    } catch (err) {
      console.log(err);
    }
  }

  // useEffect(() => {
  //     handleLoadRoutes(companyId)
  // }, [])

  const handleCreateRoute = async () => {
    const data = {
      coachCompanyId: companyId,
      endPointId: secondDistrict,
      startPointId: firstDistrict,
    };
    const res = await dispatch(requestCreateRoute(data));
    const tmp = await unwrapResult(res);
    if (tmp.error == 0) {
      handleLoadRoutes(companyId);
    }
  };

  const handleDeleteRoute = async (id) => {
    const res = await apiDeleteRoute({ id: id });
    if (res.data.error == 0) {
      handleLoadRoutes(companyId);
    }
  };
  const okButtonProps = {
    className: "text-green-700",
  };

  return (
    <div className="space-y-4">
      {
        <>
          <div>
            <Modal
              open={modalShow}
              onCancel={() => setModalShow(false)}
              onOk={handleCreateRoute}
              width={800}
            >
              <h1 className="text-lg">Tạo tuyến xe</h1>
              <div className="flex flex-row space-x-4 justify-between mt-4">
                <div className="flex space-x-4 w-full">
                  <Select
                    className="w-1/2"
                    options={listProvince}
                    style={{ height: 50 }}
                    defaultValue="Chọn tỉnh/thành phố"
                    onChange={(value) => loadFirstDistrict(value)}
                  />
                  <Select
                    className="w-1/2"
                    options={listFirstDistrict}
                    style={{ height: 50 }}
                    defaultValue="Chọn quận/huyện"
                    onChange={(value) => setFirstDistrict(value)}
                  />
                </div>
                <div className="mt-4">
                  <SwapOutlined />
                </div>
                <div className="flex space-x-4 w-full">
                  <Select
                    className="w-1/2"
                    options={listProvince}
                    style={{ height: 50 }}
                    defaultValue="Chọn tỉnh/thành phố"
                    onChange={(value) => loadSecondDistrict(value)}
                  />
                  <Select
                    className="w-1/2"
                    options={listSecondDistrict}
                    style={{ height: 50 }}
                    defaultValue="Chọn quận/huyện"
                    onChange={(value) => setSecondDistrict(value)}
                  />
                </div>
              </div>
            </Modal>
          </div>
          <div className="bg-white h-full p-6 space-y-2 rounded-lg">
            <p>{`Danh sách tuyến xe`}</p>
            <div className="flex flex-row space-x-4 justify-between">
              <div className="flex flex-row w-full space-x-4">
                <Select
                  options={optionsListRoute}
                  placeholder="Chọn tuyến xe"
                  className="w-1/2"
                  onSelect={(value) => setCurrentRoute(value)}
                ></Select>
                <div className="flex flex-row space-x-2">
                  <Tooltip title="Tạo giá vé toàn tuyến và giá vé các chặng phụ">
                    <Button
                      icon={<IconRouteTemplate />}
                      className="route-template-btn"
                      onClick={() => setIsTemplate(!isTemplate)}
                    />
                  </Tooltip>

                  <Popconfirm
                    title={
                      <p className="text-green-700 font-bold">
                        Bạn muốn xóa tuyến này?
                      </p>
                    }
                    okText="Có"
                    cancelText="Không"
                    okButtonProps={okButtonProps}
                    onConfirm={() => handleDeleteRoute(currentRoute)}
                  >
                    <Button className="del-btn" icon={<DeleteFilled />} />
                  </Popconfirm>
                </div>
              </div>

              <Button
                onClick={() => setModalShow(true)}
                className="justify-end text-white hover:bg-white"
              >
                Tạo tuyến
              </Button>
            </div>
            {isTemplate && currentRoute && (
              <RouteTemplate routeId={currentRoute} />
            )}
          </div>
        </>
      }
    </div>
  );
};

export default ModalRoute;
