import { Input, Button, Form, Select } from "antd";
import { PlusCircleOutlined, DeleteFilled } from "@ant-design/icons";
import { useAppSelector } from "../../../../../../redux/hook";
import { useEffect, useState } from "react";
import {
  apiCreateRouteTemplate,
  apiGetRouteTemplate,
  apiUpdateRouteTempalte,
} from "../../../../../../api/services";
import { useSnackbar } from "notistack";
import MapCarPrice from "./MapCarPrice";
import { setUniqueArrayOption } from "../../../../../../utils/convertTime";

const RouteCard = ({ routeId }) => {
  const listRoute = useAppSelector((state) => state.routeState.listRoute);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const defaultCoach = [
    {
      value: 0,
      label: "Chọn loại xe",
    },
  ];
  const optionsListCoach = defaultCoach.concat(
    listCoach.map((coach) => ({
      value: coach.coachType.id,
      label: coach.coachType.name,
    }))
  );
  const [listSection, setListSection] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [startPoint, setStartPoint] = useState();
  const [disable, setDisable] = useState(true);
  const [limitOption, setLimitOption] = useState([
    { value: 0, label: "Chọn loại xe" },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const handleCreateTemplate = async () => {
    const sectionListRequest = listSection.map((section) => {
      let map1 = {};
      section.priceMap?.map((price) => {
        map1[price[0]] = price[1];
      });
      let map2 = {};
      section.secondPriceMap?.map((price) => {
        map2[price[0]] = price[1];
      });
      return {
        ...section,
        spendTime: parseInt(section.spendTime),
        priceMap: map1,
        secondPriceMap: map2,
      };
    });
    const res = await apiCreateRouteTemplate({
      coachRouteId: routeId,
      sectionList: sectionListRequest,
    });
    if (!res.data.error) {
      enqueueSnackbar("Khởi tạo thành công", {
        variant: "success",
      });
      setDisable(true);
      handleLoadData();
    }
  };

  const handleUpdateTemplate = async () => {
    const sectionListRequest = listSection.map((section) => {
      let map1 = {};
      section.priceMap?.map((price) => {
        map1[price[0]] = price[1];
      });
      let map2 = {};
      section.secondPriceMap?.map((price) => {
        map2[price[0]] = price[1];
      });
      return {
        ...section,
        spendTime: parseInt(section.spendTime),
        priceMap: map1,
        secondPriceMap: map2,
      };
    });
    const res = await apiUpdateRouteTempalte({
      coachRouteId: routeId,
      sectionList: sectionListRequest,
    });
    if (!res.data.error) {
      enqueueSnackbar("Cập nhật thành công", {
        variant: "success",
      });
      setDisable(true);
      handleLoadData();
    }
  };

  const handleLoadData = async () => {
    setLimitOption([]);
    setListSection([]);
    const res = await apiGetRouteTemplate(routeId);
    if (!res.data.error) {
      if (res.data.data.sectionList.length !== 0) {
        setIsNew(false);
      }
      const tmp = res.data.data
        ? res.data.data.sectionList.map((section) => ({
            ...section,
            priceMap: Object.entries(section.priceMap),
            secondPriceMap: Object.entries(section.secondPriceMap),
          }))
        : null;
      setListSection(tmp ?? []);
      const priceMapMainRoute = res.data.data?.sectionList[0];
      if (!priceMapMainRoute?.priceMap) return;
      setLimitOption(
        setUniqueArrayOption(
          optionsListCoach.filter((item) =>
            Object.keys(priceMapMainRoute?.priceMap).includes(
              item.value.toString()
            )
          )
        )
      );
    }
  };

  useEffect(() => {
    handleLoadData();
    setDisable(true);
    const tmp = listRoute.find((route) => route.id === routeId);
    setStartPoint(tmp.startPoint.district);
  }, [routeId]);

  return (
    <div className="bg-gray-200 space-y-4 py-4 px-2">
      <p className="text-black italic text-[14px]">
        Nhập giá vé toàn tuyến: loại xe + giá tiền
      </p>
      <div className="w-2/3 min-w-80">
        <MapCarPrice
          index={0}
          listSection={listSection}
          setListSection={setListSection}
          setDisable={setDisable}
          mainRoute
          limitOption={limitOption}
          setLimitOption={setLimitOption}
          routeId={routeId}
        />
      </div>
      <p className="text-red-500 italic text-[14px]">
        *Bạn hãy nhập số lượng "phút" kể từ{" "}
        <span className="text-black">{startPoint}</span> để đến được điểm đón.
      </p>
      {listSection?.map(
        (section, index) =>
          section.type == 1 && (
            <Item
              spendTime={section?.spendTime ?? 0}
              dropOffPointIdList={section?.dropOffPointIdList}
              pickUpPointIdList={section?.pickUpPointIdList}
              price={section?.price}
              index={index}
              listSection={listSection}
              setListSection={setListSection}
              key={index}
              setDisable={setDisable}
              limitOption={limitOption}
              setLimitOption={setLimitOption}
            />
          )
      )}
      {
        <div
          className="flex flex-row space-x-1 w-fit cursor-pointer hover:bg-white p-1 rounded-md"
          onClick={() => {
            setListSection((prev) => [...prev, { type: 1 }]);
          }}
          style={{ color: "#006D38" }}
        >
          <PlusCircleOutlined />
          <p>Thêm chặng</p>
        </div>
      }

      <div className="flex items-center justify-center">
        <Button
          onClick={() =>
            isNew ? handleCreateTemplate() : handleUpdateTemplate()
          }
          className="w-1/6"
          disabled={disable}
        >
          Lưu
        </Button>
      </div>
    </div>
  );
};

export default RouteCard;

const Item = ({ index, listSection, setListSection, setDisable, ...props }) => {
  const { spendTime, limitOption, setLimitOption } = { ...props };
  const [form] = Form.useForm();
  const [time, setTime] = useState();
  useEffect(() => {
    form.setFieldsValue({
      ...props,
      spendTime: spendTime ? spendTime : null,
    });
  }, [listSection]);

  // const handleChooseTime = (e) => {
  //   console.log(e.target.value)
  //   const tmp = parseInt(e.target.value);
  //   setTime(tmp * 1000 * 60);
  // };

  // const handleDeleteSection = (index) => {
  //   listSection.splice(index, 1);
  //   setListSection([...listSection]);
  // };

  const listPoint = useAppSelector((state) => state.companyState.listPoint);
  const optionsListPoint = listPoint?.map((point) => ({
    value: point.locationId,
    label: point.address,
  }));
  return (
    <Form
      form={form}
      onValuesChange={() => {
        const tmp = { ...listSection[index], ...form.getFieldsValue() };
        listSection[index] = tmp;
        setDisable(false);
      }}
    >
      <div className="flex flex-row">
        <h2 className="mt-1 w-16">Chặng {index}</h2>
        <div className="grid grid-cols-12 w-full space-x-1">
          <Form.Item name="spendTime" className="col-span-1">
            <Input
              placeholder="Thời gian"
              suffix={"p"}
              // onChange={(e) => handleChooseTime(e)}
            />
          </Form.Item>
          <Form.Item name="pickUpPointIdList" className="col-span-3">
            <Select
              placeholder="Chọn điểm đón"
              className="w-full"
              mode="multiple"
              maxTagCount={1}
            >
              {optionsListPoint.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="dropOffPointIdList" className="col-span-3">
            <Select
              placeholder="Chọn điểm trả"
              maxTagCount={1}
              options={optionsListPoint}
              className="w-full"
              mode="multiple"
            ></Select>
          </Form.Item>
          <MapCarPrice
            listSection={listSection}
            setListSection={setListSection}
            setDisable={setDisable}
            index={index}
            limitOption={limitOption}
            setLimitOption={setLimitOption}
          />
          {/* <Form.Item name="price" className="col-span-2">
          <Input placeholder="Giá tiền" suffix="VND" className="w-full"></Input>
        </Form.Item> */}
        </div>
        <Button
          className="del-btn"
          classNames="w-3"
          onClick={() => {
            listSection.splice(index, 1);
            setListSection([...listSection]);
            setDisable(false);
          }}
          icon={<DeleteFilled />}
        />
      </div>
    </Form>
  );
};
