import { useEffect, useState } from "react";
import { LockIcon, MiniBlue, MiniRed } from "../../../../../assets/svgs";
import { Button } from "antd";
import ModalTicketSeller from "./ModalTicketSeller";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import {
  requestLoadTicket,
} from "../../../../../redux/slices/ticketSlice";
import { regexNumber } from "../../../../../utils/convertTime";
import { apiLockSeat } from "../../../../../api/services";
import { useNavigate } from "react-router-dom";
import { ExpandOutlined } from "@ant-design/icons";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

const ListTicket = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentTimeslot = useAppSelector(
    (state) => state.ticketState.currentTimeslot
  );
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const listTicket = useAppSelector((state) => state.ticketState.listTicket);
  const listTimeSlot = useAppSelector((state) => state.ticketState.listTimeslot);
  const lockSeat = useAppSelector((state) => state.ticketState.lockSeat);
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const [listSeat, setListSeat] = useState([]);

  const onTriggerSeat = () => {
    navigate("/phuong-tien");
  };

  // function connect() {
  //   let socket = new SockJS(`${process.env.REACT_APP_ENDPOINT}/bookings`);
  //   let stompClient = Stomp.over(socket);

  //   stompClient.connect({}, function (frame) {
  //     stompClient.subscribe("/topic/lock-seat", function (message) {
  //       let tmp = JSON.parse(message?.body);
  //       const index = listSeat
  //         .filter((item) => item != undefined)
  //         .findIndex((item) => item.name == tmp.name);
  //       if (index != -1) {
  //         listSeat[index].state = 1;
  //       }
  //     });
  //   });
  // }

  // useEffect(() => {
  //   connect();
  // }, []);

  useEffect(() => {
    const coach = listCoach.find(
      (coach) => coach.id == currentTimeslot.coachId
    );
    if (currentTimeslot.id != "" && listTimeSlot.length) {
      dispatch(
        requestLoadTicket({
          companyId,
          coachVsc: coach?.vsc,
          timeslotDepartureTime: currentTimeslot?.departureTime,
        })
      );
    }
  }, [currentTimeslot]);

  useEffect(() => {
    if (!listTicket) return;
    if (listTicket.length == 2) {
      const list = listTicket[0].concat(listTicket[1]);
      setListSeat(list);
    } else if (listTicket.length == 1) {
      setListSeat(listTicket[0]);
    } else {
      setListSeat([]);
    }
  }, [listTicket]);
  if(!listTimeSlot.length) return null;

  if (currentTimeslot.id != "" && !listTicket)
    return (
      <div className="w-full flex flex-col space-y-2 items-center justify-center">
        <p>Chưa có sơ đồ xe, vui lòng tạo sơ đồ phương tiện!</p>
        <Button onClick={onTriggerSeat}>Tạo sơ đồ</Button>
      </div>
    );

  return (
    <div className="grid lg:grid-cols-4 2xl:grid-cols-6 gap-2 w-full max-h-screen overflow-y-scroll">
      {listSeat?.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </div>
  );
};

export default ListTicket;

const Item = (props) => {
  const listRoute = useAppSelector((state) => state.routeState.listRoute);
  const currentRoute = useAppSelector((state) => state.routeState.currentRoute);
  const currentTimeslot = useAppSelector(
    (state) => state.ticketState.currentTimeslot
  );
  const startPoint = listRoute.find((route) => route.id == currentRoute)
    ?.startPoint.province;
  const endPoint = listRoute.find((route) => route.id == currentRoute)?.endPoint
    .province;

  const [modalShow, setModalShow] = useState(false);
  const { name, state, userOrder, id } = props;

  const onLockSeat = async () => {
    const res = await apiLockSeat({
      name: name,
      timeslotId: currentTimeslot?.id,
    });
  };

  const checkInLockSeat = () => {
    // const tmp = lockSeat.findIndex(item => item.name == name && item.timeslotId == currentTimeslot?.id)
    // return tmp != -1
  };

  if (!name || name == "0" || name == "WC") return null;
  return (
    <div>
      <div
        className="rounded-lg relative border-2 border-black bg-white"
        // style={{
        //   backgroundColor:
        //     LIST_COLOR[Math.floor(Math.random() * LIST_COLOR.length)],
        // }}
        onClick={() => {
          onLockSeat();
          setModalShow(true);
        }}
      >
        {state == 1 ||
          (checkInLockSeat() && (
            <div className="absolute w-full h-full flex justify-center items-center bg-neutral-400">
              <LockIcon />
            </div>
          ))}
        <div className="w-full flex flex-row justify-between">
          <div
            className="bg-neutral-200 py-1 p-2"
            style={{
              borderBottomRightRadius: "8px",
              borderTopLeftRadius: "8px",
            }}
          >
            <p>{name}</p>
          </div>
          {userOrder?.phoneNumber && (
            <div
              className="bg-neutral-200 py-1 p-2"
              style={{
                borderBottomLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <p>{userOrder?.phoneNumber}</p>
            </div>
          )}
        </div>
        <div className="p-2">
          <p className="uppercase">{userOrder?.name ?? "Tên khách"}</p>
          <p>{regexNumber(userOrder?.price ?? currentTimeslot?.price)} VND</p>
          <p>{userOrder ? userOrder?.lastUpdateBy ? userOrder?.lastUpdateBy : "Nhà xe" : "Nhân viên bán vé"}</p>
          <div className="flex flex-row items-center">
            <MiniBlue />
            <p>{userOrder?.pickUpPoint?.district ?? "Điểm đón"}</p>
          </div>
          <div className="flex flex-row items-center">
            <MiniRed />
            <p>{userOrder?.dropOffPoint?.district  ?? "Điểm trả"}</p>
          </div>
        </div>
        <Button
          className="w-full rounded-lg"
          icon={<ExpandOutlined />}
          // onClick={() => {
          //   onLockSeat();
          //   setModalShow(true);
          // }}
        >
          Ghi chú
        </Button>
      </div>
      <ModalTicketSeller
          modalShow={modalShow}
          setModalShow={setModalShow}
          seatName={name}
          orderId={id}
        />
    </div>
  );
};

const LIST_COLOR = ["#7ED7C1", "#FFC5C5", "#C0DBEA"];
