import { Modal, Input } from "antd";
import { secondToHour } from "../../../../../utils/convertTime";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import { chooseTimeslot } from "../../../../../redux/slices/ticketSlice";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { COACH_TYPE } from "../../../../../constant";

const ModalListTimeslot = (props) => {
  const { modalShow, setModalShow, listTimeslot } = props;
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const [filter, setFilter] = useState("");
  const [configListTimeslot, setConfigListTimeslot] = useState([]);

  useEffect(() => {
    const tmp = listTimeslot.map((timeslot) => {
        const coach = listCoach.find((coach) => coach.id == timeslot.coachId);
        const numOfSeat = COACH_TYPE[coach?.coachType.id]?.nos;
        return {
            ...timeslot,
            vsc: coach?.vsc,
            nos: numOfSeat,
        };
    })
    setConfigListTimeslot(tmp)
  }, [listTimeslot]);

  return (
    <Modal
      open={modalShow}
      onCancel={() => setModalShow(false)}
      width={800}
      footer={null}
    >
      <div className="w-1/3 mb-4 ">
        <Input placeholder="Tìm kiếm" prefix={<SearchOutlined />} onChange={(e) => setFilter(e.target.value)}/>
      </div>
      <div className="grid grid-cols-[repeat(auto-fill,_108px)] gap-4">
        {configListTimeslot
          ? configListTimeslot.filter((e) => e?.vsc?.toLowerCase().includes(filter)).map((time, index) => (
              <Item key={index} timeslot={time} setModalShow={setModalShow} />
            ))
          : null}
      </div>
    </Modal>
  );
};

export default ModalListTimeslot;

const Item = (props) => {
  const { timeslot, setModalShow } = props;
  const dispatch = useAppDispatch();
  const onChoseTimeslot = () => {
    dispatch(chooseTimeslot(timeslot));
    setModalShow(false);
  };

  return (
    <div
      className="flex flex-col h-fit items-center border-2 border-neutral-400 rounded-md min-w-28"
      onClick={onChoseTimeslot}
    >
      <div className="bg-white flex w-full justify-center rounded-t-md cursor-pointer">
        {secondToHour(timeslot?.departureTime / 1000)}
      </div>
      <div className="bg-[#80B155] text-white flex flex-row w-full justify-center cursor-pointer gap-4 px-1">
        <span className="text-[12px]">{timeslot?.vsc}</span>
        <span className="text-[12px]">{`${timeslot?.totalOrders}/${timeslot?.nos}`}</span>
      </div>
    </div>
  );
};
