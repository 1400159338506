import { COACH_TYPE } from "../../../../../../constant";
import { Modal, Typography, Radio, Space, Button } from "antd";
import { useState, useEffect } from "react";
import CoachType from "../../../transport/components/CoachType";
import { apiCreateSeatConfig, apiGetSeatConfig, apiUpdateSeatConfig } from "../../../../../../api/services";
import { enqueueSnackbar } from "notistack";

const { Title } = Typography;

const ModalSeatConfig = (props) => {
  const { modalShow, setModalShow, coachTypeId, routeId } = props;
  const [seatList, setSeatList] = useState([]);
  const [seatLimit, setSeatLimit] = useState(0);
  const [configId, setConfigId] = useState();

  const [value, setValue] = useState(0);
  const [type, setType] = useState(0);

  const fetchData = async () => {
    const res = await apiGetSeatConfig({
      coachTypeId: coachTypeId,
      coachRouteId: routeId,
    })
    if(!res.data.error) {
      const data = res.data.data;
      setSeatList(data.seatList);
      setSeatLimit(data.seatLimit);
      setConfigId(data.id);
      setType(data.type == null ? null : (data.type == 0 ? 2 : 1));
    } else {
      setSeatLimit(0);
      setSeatList([]);
      setConfigId(null);
      setType(0);
    }
  }
  useEffect(() => {
    setValue(0);
  }, [modalShow])

  useEffect(() => {
    if(modalShow) {
      fetchData();
    }
  }, [coachTypeId])


  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onCreateSeatConfig = async () => {
    const data = {
      coachTypeId: coachTypeId,
      seatList: seatList,
      seatLimit: seatLimit,
      type: value == 2 ? 0 : 1,
      routeId: routeId,
    };
    const res = await apiCreateSeatConfig(data);
    if(!res.data.error) {
      setModalShow(false);
      enqueueSnackbar("Tạo thành công", {
        variant: "success",
      })
    } else {
      enqueueSnackbar("Có lỗi xảy ra", {
        variant: "error",
      })
    }
  };

  const onUpdateSeatConfig = async () => {
    const data = {
      id: configId, 
      seatList: value == 1 ? seatList : [],
      seatLimit: value == 2 ? seatLimit : 0,
      type: value == 2 ? 0 : 1,
    };
    const checkData = {
      ...data,
      type: seatList.length == 0 && data.type == 1 || seatLimit == 0 && data.type == 0 ? null : data.type
    }
    const res = await apiUpdateSeatConfig(checkData);
    if(!res.data.error) {
      setModalShow(false);
      fetchData();
      enqueueSnackbar("Cập nhật thành công", {
        variant: "success",
      })
    } else {
      enqueueSnackbar("Có lỗi xảy ra", {
        variant: "error",
      })
    }
  }

  return (
    <Modal
      width={"fit-content"}
      style={{ minWidth: "40%" }}
      open={modalShow}
      onCancel={() => setModalShow(false)}
      onOk={configId ? onUpdateSeatConfig : onCreateSeatConfig}
      footer={(_, { OkBtn, CancelBtn }) => (
        <div>
          {!!value && (
            <div className="flex flex-row justify-center space-x-2">
              <Button
                className="del-btn"
                onClick={() => {
                  setValue(0);
                }}
              >
                Hủy
              </Button>
              <OkBtn>Xác nhận</OkBtn>
            </div>
          )}
        </div>
      )}
    >
      {!value && (
        <div>
          <div className="border-b-2 w-full flex items-center justify-center">
            <Title level={4}>Chọn kiểu phòng đôi muốn bán</Title>
          </div>
          <Radio.Group value={type} onChange={onChange}>
            <Space direction="vertical" className="mt-4">
              <Radio value={1} onClick={() => setValue(1)}>Vị trí chỉ định</Radio>
              <Radio value={2} onClick={() => setValue(2)}>Vị trí tự do và số lượng vé tùy chọn</Radio>
            </Space>
          </Radio.Group>
        </div>
      )}
      {value == 2 && (
        <Item
          coachTypeId={coachTypeId}
          seatLimit={seatLimit}
          setSeatLimit={setSeatLimit}
        />
      )}
      {value == 1 && (
        <CoachType
          coachType={coachTypeId}
          seatConfig={true}
          seatList={seatList}
          setSeatList={setSeatList}
        />
      )}
    </Modal>
  );
};

export default ModalSeatConfig;

const Item = (props) => {
  const { coachTypeId, seatLimit, setSeatLimit } = props;
  return (
    <div>
      <div className="border-b-2 w-full h-16 flex items-center justify-around">
        <Title level={4}>Chọn số lượng phòng đôi được bán</Title>
      </div>
      <div className="flex flex-row mt-4 space-x-0.5">
        <Button
          className={`
               w-8 text-center text-xl border-green-700 border hover:bg-green-700 hover:text-white cursor-pointer`}
          onClick={() => {
            let tmp =
              seatLimit < COACH_TYPE[coachTypeId].nos
                ? seatLimit + 1
                : COACH_TYPE[coachTypeId].nos;
            setSeatLimit(tmp);
          }}
        >
          +
        </Button>
        <Button
          className={`
              w-8 text-center text-xl border-green-700 border hover:bg-green-700 hover:text-white cursor-pointer`}
          onClick={() => {
            let tmp = seatLimit > 0 ? seatLimit - 1 : 0;
            setSeatLimit(tmp);
          }}
        >
          -
        </Button>
        <div className="border-green-700 border-2 w-full text-center items-center">
          <p>
            {seatLimit}/{COACH_TYPE[coachTypeId].nos}
          </p>
        </div>
      </div>
    </div>
  );
};
