import { ForgetPasswordScreen } from "../forget-password";
import { LoginScreen } from "./index";

export const authRouteList = [
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/forget-password",
    element: <ForgetPasswordScreen />
  }
];
