import { useEffect, useState } from "react";
import { COACH_TYPE } from "../../../../../constant";
import { Seat } from "./Seat";
import TicketForm from "./TicketForm";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import { requestLoadTicket } from "../../../../../redux/slices/ticketSlice";
import { regexNumber } from "../../../../../utils/convertTime";
import { Button } from "antd";

const RollCall = () => {
  const dispatch = useAppDispatch();
  const currentTimeslot = useAppSelector(
    (state) => state.ticketState.currentTimeslot
  );
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const listTicket = useAppSelector((state) => state.ticketState.listTicket);
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const coachOnTimeslot = useAppSelector(
    (state) => state.ticketState.coachOnTimeslot
  );
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [spr, setSpr] = useState(0);
  const coachIndex = listCoach.findIndex(
    (item) => item.id == currentTimeslot.coachId
  );
  const coachType = listCoach[coachIndex].coachType.id;

  const [currentSeat, setCurrentSeat] = useState(null);

  useEffect(() => {
    if (!listTicket) return;
    setFirst(listTicket[0] ?? []);
    setSecond(listTicket[1] ?? []);
    setSpr(COACH_TYPE[coachType].spr ?? 3);
  }, [listTicket]);
  useEffect(() => {
    const coach = listCoach.find((coach) => coach.id == currentTimeslot.coachId);
    if (currentTimeslot.id != "") {
      dispatch(
        requestLoadTicket({
          companyId,
          coachVsc: coach?.vsc,
          timeslotDepartureTime: currentTimeslot?.departureTime,
        })
      );
    }
  }, [currentTimeslot]);

  return (
    <div className="grid grid-cols-5 w-full gap-4">
      <div className="col-span-3 w-full grid grid-cols-2 bg-white pt-8 rounded-md">
        <div className="col-span-1 w-full flex flex-col items-center border-r-2 border-gray-300">
        {!!second.length && <p>Tầng dưới</p>}
          <div
            className={`grid grid-cols-${spr} gap-2 mt-3 ${
              spr == 3 ? "w-48" : "w-64"
            }`}
          >
            {first.map((seat, index) => (
              <Seat
                id={seat.id}
                num={seat.name}
                state={seat.state}
                index={index}
                key={index}
                setCurrentSeat={setCurrentSeat}
              />
            ))}
          </div>
        </div>
        <div className="col-span-1 w-full flex flex-col items-center">
          {!!second.length && <p>Tầng trên</p>}
          <div
            className={`col-span-1 grid grid-cols-${spr} gap-2 mt-3 ${
              spr == 3 ? "w-48" : "w-64"
            }`}
          >
            {second &&
              second.map((seat, index) => (
                <Seat
                  id={seat.id}
                  num={seat.name}
                  state={seat.state}
                  index={index}
                  key={index}
                  setCurrentSeat={setCurrentSeat}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="col-span-2 bg-white p-2 rounded-md">
        <div className="flex flex-col">
          <div className="flex flex-row space-x-16">
            <p>
              Giá vé:{" "}
              {regexNumber(coachOnTimeslot.price ?? currentTimeslot.price)}đ
            </p>
            <p className="text-red-500">
              Tổng:{" "}
              {regexNumber(coachOnTimeslot.price ?? currentTimeslot.price)}đ
            </p>
          </div>
          <div className="flex flex-row space-x-16">
            <p>SL: 1</p>
            <p>Số ghế: {currentSeat?.num}</p>
          </div>
        </div>
        <TicketForm orderId={currentSeat?.id} currentSeat={currentSeat} />
      </div>
    </div>
  );
};

export default RollCall;
