import { LoadingOutlined } from '@ant-design/icons'

const LoadingPage = () => {
    return(
        <div style={{ width: "100%", height: "80vh", color: "#006D38", display: "flex", alignItems: "center", justifyContent: "center" }} className='flex flex-col space-y-4'>
            <LoadingOutlined style={{ fontSize: '64px' }} />
            <p>Vui lòng đợi trong ít phút ...</p>
        </div>
    )
}

export default LoadingPage