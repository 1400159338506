import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../redux/hook";
import {
  requestLoadPickUpPointList,
  requestLoadDropOffPointList,
  requestFindCoach,
} from "../../../../../../redux/slices/companySlice";
import { Button, Form, Select, DatePicker } from "antd";
import { Swap } from "../../../../../../assets/svgs";
import dayjs from "dayjs";

const ModalSearchTicket = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const pickUpPoint = useAppSelector((state) => state.companyState.pickUpPoint);
  const dropOffPoint = useAppSelector(
    (state) => state.companyState.dropOffPoint
  );
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  // const listSearchTicket = useAppSelector(
  //   (state) => state.companyState.listSearchTicket
  // );
  const [date, setDate] = useState(dayjs(new Date()).startOf("day"));
  const onSelect = (props) => {
    const { pointId, type } = props;
    type
      ? dispatch(
          requestLoadDropOffPointList({
            companyId,
            pickUpPointId: pointId,
          })
        )
      : dispatch(
          requestLoadPickUpPointList({
            companyId,
            dropOffPointId: pointId,
          })
        );
  };

  const onSearch = async () => {
    const tmp = form.getFieldsValue();
    const data = {
      companyId,
      startPointId: tmp.startPointId,
      endPointId: tmp.endPointId,
      departureTime: date.valueOf(),
      returnTime: 0,
    };
    dispatch(requestFindCoach(data));
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      startPointId: "Nhập điểm đón",
      endPointId: "Nhập điểm trả",
      departureTime: 0,
    });
    dispatch(
      requestLoadPickUpPointList({
        companyId,
        dropOffPointId: "",
      })
    );
    dispatch(
      requestLoadDropOffPointList({
        companyId,
        pickUpPointId: "",
      })
    );
  }, []);

  return (
    <div>
      <div>
        <div className="flex justify-center">
          <h4>Đặt vé nhanh</h4>
        </div>
        <Form form={form}>
          <div className="flex flex-row space-x-2 w-full">
            <Form.Item name="startPointId" className="w-1/3">
              <Select
                options={pickUpPoint}
                placeholder="Nhập điểm đón"
                className="w-full"
                onSelect={(pointId) => onSelect({ pointId, type: 1 })}
              />
            </Form.Item>

            <Swap />
            <Form.Item name="endPointId" className="w-1/3">
              <Select
                options={dropOffPoint}
                placeholder="Nhập điểm trả"
                className="w-full"
                onSelect={(pointId) => onSelect({ pointId, type: 0 })}
              />
            </Form.Item>
            <Form.Item name="departureTime" className="w-1/3">
              <DatePicker
                format="DD/ MM/ YY"
                value={dayjs(new Date())}
                className="w-full"
                onChange={(e) => {
                  setDate(dayjs(e).startOf("day"));
                }}
              />
            </Form.Item>
          </div>
        </Form>

        <Button onClick={() => onSearch()}>Tìm kiếm</Button>
      </div>
      <div>
        <h3>Kết quả tìm kiếm:</h3>
        {
          // listSearchTicket.map(search => <SearchCard />)
        }
      </div>
    </div>
  );
};

export default ModalSearchTicket;
