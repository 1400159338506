import { Modal } from "antd";
import CoachType from "../../../transport/components/CoachType";
import Title from "antd/lib/typography/Title";

const ModalPriceDiff = (props) => {
  const {
    modalShow,
    setModalShow,
    coachId,
    listSection,
    setListSection,
    routeId
  } = props;


  return (
    <div>
      <Modal
        open={modalShow}
        width={"fit-content"}
        onCancel={() => setModalShow(false)}
        footer={null}
        centered
      >
        <div className="flex flex-col items-center">
          <Title level={4}>Chỉnh sửa giá vé phụ</Title>
          <CoachType
            coachType={coachId}
            isPrice={true}
            setFatherModalShow={setModalShow}
            listSection={listSection}
            setListSection={setListSection}
            routeId={routeId}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ModalPriceDiff;
