import { requestLoadListOffce } from "../../../../redux/slices/companySlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import Header from "./components/Header";
import ListTicket from "./components/ListTicket";
import ListTimeslot from "./components/ListTimeslot";
import RollCall from "./components/RollCall";
import { useEffect, useState } from "react";
import { resetData } from "../../../../redux/slices/ticketSlice";

const TicketSeller = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.authState.userInfo.id);
  const [rollCall, setRollCall] = useState(false);

  useEffect(() => {
    dispatch(requestLoadListOffce(companyId));
    dispatch(resetData());
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      <Header rollCall={rollCall} setRollCall={setRollCall} />
      <div className="flex flex-row w-full space-x-2 relative">
        <div>
          <ListTimeslot />
        </div>
        <div className="flex w-full">
          {rollCall ? <RollCall coachType={21} /> : <ListTicket />}
        </div>
      </div>
    </div>
  );
};

export default TicketSeller;
