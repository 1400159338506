import {
  Card,
  Input,
  Select,
  Row,
  Form,
  Button,
  Typography,
  TimePicker,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  CaretRightFilled,
  DeleteFilled,
  PauseOutlined,
} from "@ant-design/icons";
import {
  apiDeleteTimeslot,
  apiTurnOnOffTimeslot,
} from "../../../../../api/services";
import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import Section from "../Section";
import { useSnackbar } from "notistack";
import {
  regexNumber,
  convertSecondsToDayjs,
  setUniqueArrayOption,
} from "../../../../../utils/convertTime";
import {
  requestLoadRouteTemplate,
  setChooseCoach,
} from "../../../../../redux/slices/routeSlice";
import { TeamOutlined, UserOutlined } from "../../../../../assets/svgs";

const TimeSlotCard = ({
  schedule,
  index,
  setCurrentIndex,
  listTimeSlot,
  setListTimeSlot,
  setCurrentTimeslot,
  currentDate,
  extraTimeslot,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const currentRoute = useAppSelector((state) => state.routeState.currentRoute);
  const currenteRouteTemplate = useAppSelector(
    (state) => state.routeState.currentRouteTemplate
  );
  const listPath = useAppSelector((state) => state.routeState.listPath);
  const listCoach = useAppSelector((state) => state.companyState.listCoach);
  const chooseCoach = useAppSelector((state) => state.routeState.chooseCoach);
  const [listTP, setListTP] = useState([]);
  const [options, setOptions] = useState([]);
  const [listSection, setListSection] = useState([]);
  const [form] = Form.useForm();
  const [time, setTime] = useState();
  const [status, setStatus] = useState(true);
  const [approveAll, setApproveAll] = useState(true);
  // const [multi, setMulti] = useState(false);
  // const [dataMulti, setDataMulti] = useState({});
  useEffect(() => {
    dispatch(requestLoadRouteTemplate(currentRoute));
  }, [currentRoute]);
  useEffect(() => {
    !schedule.status ? setStatus(true) : setStatus(false);
  }, [schedule]);

  useEffect(() => {
    !schedule.status ? setStatus(true) : setStatus(false);
    if (chooseCoach == 0) return;
    const priceMainRoute = schedule.price
      ? schedule.price
      : currenteRouteTemplate.filter((item) => item.type == 0)[0]?.priceMap[
          chooseCoach
        ] ?? 0;
    const secondPriceMainRoute = schedule.secondPrice
      ? schedule.secondPrice
      : currenteRouteTemplate.filter((item) => item.type == 0)[0]
          ?.secondPriceMap[chooseCoach] ?? 0;
    form.setFieldValue("price", regexNumber(priceMainRoute));
    form.setFieldValue("secondPrice", regexNumber(secondPriceMainRoute));
    const tmp = {
      ...listTimeSlot[index],
      price: priceMainRoute,
    };

    setListTimeSlot([
      ...listTimeSlot.slice(0, index),
      tmp,
      ...listTimeSlot.slice(index + 1),
    ]);
  }, [chooseCoach]);

  useEffect(() => {
    form.resetFields();
    setTime(null);
    schedule?.sectionList
      ? setListSection(schedule.sectionList)
      : setListSection(currenteRouteTemplate?.filter((item) => item.type == 1));
    form.setFieldsValue(schedule);
    if (schedule?.coachId && !extraTimeslot) {
      const tmp = listCoach.filter((coach) => coach.id == schedule.coachId);
      dispatch(setChooseCoach(tmp[0].coachType.id));
    }
    if (schedule?.price) {
      form.setFieldValue("price", regexNumber(schedule.price));
    }
    if(schedule?.secondPrice){
      form.setFieldValue("secondPrice", regexNumber(schedule.secondPrice));
    }
    if (schedule?.departureTime || schedule?.departureTime == 0) {
      form.setFieldValue(
        "departureTime",
        convertSecondsToDayjs(schedule?.departureTime)
      );
      setTime(schedule?.departureTime);
    } else {
      form.setFieldValue("departureTime");
    }
  }, [schedule, currenteRouteTemplate]);

  useEffect(() => {
    const listCoachIdHavePrice = currenteRouteTemplate[0]?.priceMap
      ? Object.keys(currenteRouteTemplate[0]?.priceMap)
      : [];
    const coachesHavePrice = listCoach.filter((coach) =>
      listCoachIdHavePrice.includes(coach.coachType.id.toString())
    );
    let lc = coachesHavePrice.map((coach) => {
      return {
        value: coach.id,
        label: `${coach.coachType.name}`,
      };
    });
    setOptions(setUniqueArrayOption(lc));
    const tmp = listPath.map((p) => ({
      value: p.id,
      label: p.name,
    }));
    setListTP(tmp);
  }, []);

  const onChooseCoach = (e) => {
    const tmp = listCoach.filter((coach) => coach.id == e);
    dispatch(setChooseCoach(tmp[0].coachType.id));
    // setCurrentCoach(tmp[0].coachType.id)
  };

  const onChangeStatus = async () => {
    setStatus(!status);
    const props = {
      id: schedule.id,
      offDates: [currentDate],
      status: status ? 1 : 0,
    };

    const res = await apiTurnOnOffTimeslot(props);
    if (!res.data.error) {
      enqueueSnackbar("Cập nhật thành công", {
        variant: "success",
      });
      setListTimeSlot([
        ...listTimeSlot.slice(0, index),
        { ...schedule, status: status ? 1 : 0 },
        ...listTimeSlot.slice(index + 1),
      ]);
    }
  };

  const onChangeApprove = () => {
    const tmp = approveAll;
    const priceAll = form.getFieldValue("price");
    setApproveAll(!approveAll);
    const listSectionApproveAll = listSection.map((section) => ({
      ...section,
      price: priceAll,
    }));
    const listSectionRoute = listSection.map((section, index) => ({
      ...section,
      price: currenteRouteTemplate[index + 1]?.priceMap[chooseCoach],
    }));
    tmp
      ? setListSection(listSectionApproveAll)
      : setListSection(listSectionRoute);
    setListTimeSlot([
      ...listTimeSlot.slice(0, index),
      {
        ...listTimeSlot[index],
        sectionList: tmp ? listSectionApproveAll : listSectionRoute,
      },
      ...listTimeSlot.slice(index + 1),
    ]);
  };

  const handleDeleteTimeslot = async (id) => {
    if (!id) {
      delete listTimeSlot[index];
      index
        ? setCurrentTimeslot(listTimeSlot[index - 1])
        : setCurrentTimeslot(null);
      index ? setCurrentIndex(index - 1) : null;
      let tmp = listTimeSlot.filter((tl) => tl);
      setListTimeSlot(tmp);
    } else {
      const timeslot = listTimeSlot.filter((item) => item.id == id);
      const tmp = {
        coachRouteId: currentRoute,
        id: id,
        departureTime: timeslot[0].departureTime,
        type: 1,
      };
      const res = await apiDeleteTimeslot(tmp);
      if (res.data.error == 0) {
        delete listTimeSlot[index];
        setCurrentTimeslot(listTimeSlot[index - 1]);
        setCurrentIndex(index - 1);
        let tmp = listTimeSlot.filter((tl) => tl);
        setListTimeSlot(tmp);
      }
    }
  };

  const handleChooseTime = (e) => {
    const listTmp = currenteRouteTemplate.filter((item) => item.type == 1);
    setTime((e.$H * 3600 + e.$m * 60) * 1000);

    const tmp = listSection.map((section, index) => {
      const timeStart = (e.$H * 3600 + e.$m * 60) * 1000;
      const timeToGo = section.spendTime
        ? section.spendTime * 60 * 1000
        : currenteRouteTemplate[index]?.spendTime * 60 * 1000 ?? 0;
      return {
        ...section,
        departureTime: timeStart + timeToGo,
      };
    });
    setListSection(tmp);
    const timeslot = {
      ...form.getFieldsValue(),
      departureTime: (e.$H * 3600 + e.$m * 60) * 1000,
      sectionList: tmp,
    };
    setListTimeSlot([
      ...listTimeSlot.slice(0, index),
      timeslot,
      ...listTimeSlot.slice(index + 1),
    ]);
  };

  const okButtonProps = {
    className: "text-green-700",
  };

  return (
    <div>
      <Card className="bg-neutral-200 my-6">
        <Form
          form={form}
          onValuesChange={() => {
            const tmp = {
              ...form.getFieldsValue(),
              departureTime: time,
              sectionList: listSection,
            };
            setListTimeSlot([
              ...listTimeSlot.slice(0, index),
              tmp,
              ...listTimeSlot.slice(index + 1),
            ]);
          }}
        >
          <Row className="space-x-1 grid grid-cols-12">
            <Typography.Title level={4} className="col-span-1">
              {index + 1
                ? index < 9
                  ? `Tài 0${index + 1}`
                  : `Tài ${index + 1}`
                : null}
            </Typography.Title>
            <Form.Item name="departureTime" className="col-span-1">
              <TimePicker
                showNow={false}
                needConfirm={false}
                format="HH:mm"
                placeholder="Giờ"
                onChange={(e) => handleChooseTime(e)}
                className="w-full"
                allowClear={false}
              />
            </Form.Item>
            <Form.Item name="coachId" className="col-span-3" required>
              <Select
                defaultValue="Chọn loại xe"
                className="w-full"
                onSelect={(e) => onChooseCoach(e)}
              >
                {options.map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="travelPathId" className="col-span-3">
              <Select
                defaultValue="Chọn lộ trình"
                className="w-full"
                options={listTP}
              ></Select>
            </Form.Item>
            <div className="col-span-2">
              <Form.Item name="price" style={{ margin: "0px"}}>
                <Input suffix="VND" className="w-full map-input" disabled prefix={<UserOutlined />}/>
              </Form.Item>
              {chooseCoach > 9 && chooseCoach < 15 && (
                <Form.Item name="secondPrice">
                  <Input suffix="VND" className="w-full map-input" disabled prefix={<TeamOutlined />}/>
                </Form.Item>
              )}
            </div>

            <div className="col-span-2 flex flex-row space-x-1">
              <Tooltip title={"Tạm dừng/Tiếp tục chuyến xe"}>
                <Button
                  icon={status ? <PauseOutlined /> : <CaretRightFilled />}
                  className="pause-btn"
                  onClick={onChangeStatus}
                />
              </Tooltip>
              <Popconfirm
                title={
                  <p className="text-green-700 font-bold">
                    Bạn muốn xóa lịch tuyến này?
                  </p>
                }
                okText="Có"
                cancelText="Không"
                okButtonProps={okButtonProps}
                onConfirm={() => handleDeleteTimeslot(schedule?.id)}
              >
                <Button className="del-btn" icon={<DeleteFilled />} />
              </Popconfirm>
            </div>
          </Row>
          <Form.Item name="id" className="hidden" />
        </Form>
        <div>
          {!!listSection.length &&
            listSection?.map((t, sectionIndex) => (
              <Section
                key={sectionIndex}
                timeToGo={time}
                section={t}
                index={sectionIndex}
                listSection={listSection}
                setListSection={setListSection}
                listTimeslot={listTimeSlot}
                setListTimeslot={setListTimeSlot}
                timeslotIndex={index}
                currentCoach={chooseCoach}
                isDisabled={schedule.id ? true : false}
              />
            ))}
        </div>
      </Card>
    </div>
  );
};

export default TimeSlotCard;
