export const COACH_TYPE = {
  1: {
    name: "Xe 4 chỗ",
    first: ["0", null, "", "", "", ""],
    second: [],
    nos: 4,
    spr: 3,
  },
  2: {
    name: "Xe 7 chỗ",
    first: ["0", null, "", "", "", "", "", "", ""],
    second: [],
    nos: 7,
    spr: 3,
  },
  3: {
    name: "Xe 9 Ghế Limousine",
    first: ["0", "", "", "", null, "", "", null, "", "", "", ""],
    second: [],
    nos: 9,
    spr: 3,
  },
  4: {
    name: "Xe 11 Ghế Limousine",
    first: ["0", "", "", "", null, "", "", null, "", "", null, "", "", "", ""],
    second: [],
    nos: 11,
    spr: 3,
  },
  5: {
    "name:": "Xe 16 ghế Limousine",
    first: [
      "0",
      null,
      "",
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 16,
    spr: 4,
  },
  6: {
    "name:": "Xe 19 ghế",
    first: [
      "0",
      null,
      null,
      null,
      "",
      "",
      null,
      null,
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 19,
    spr: 4,
  },
  7: {
    name: "Xe 19 phòng Limousine",
    first: [
      "0",
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 19,
    spr: 4,
  },
  8: {
    "name:": "Xe 20 ghế",
    first: [
      "0",
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 20,
    spr: 4,
  },
  9: {
    "name:": "Xe 20 phòng",
    first: ["0", null, "", "", "", "", "", "", "", "", "", ""],
    second: [null, null, "", "", "", "", "", "", "", "", "", ""],
    nos: 20,
    spr: 2,
  },
  10: {
    "name:": "Xe 20 Phòng Limousine (Có WC)",
    first: ["0", null, "", "", "", "", "", "", "", "", "", "", null, "WC"],
    second: [null, null, "", "", "", "", "", "", "", "", "", "", null, "WC"],
    nos: 20,
    spr: 2,
  },
  11: {
    "name:": "Xe 21 Phòng Limousine (Có WC)",
    first: ["0", null, "", "", "", "", "", "", "", "", "", "", null, "WC"],
    second: [null, null, "", "", "", "", "", "", "", "", "", "", "", "WC"],
    nos: 21,
    spr: 2,
  },
  12: {
    name: "Xe 22 phòng Limousine(có WC)",
    first: ["0", null, "", "", "", "", "", "", "", "", "", "", "", "WC"],
    second: [null, null, "", "", "", "", "", "", "", "", "", "", "", "WC"],
    nos: 22,
    spr: 2,
  },
  13: {
    name: "Xe 22 phòng Limousine",
    first: ["0", null, "", "", "", "", "", "", "", "", "", "", null, null],
    second: [null, null, "", "", "", "", "", "", "", "", "", "", "", ""],
    nos: 22,
    spr: 2,
  },
  14: {
    name: "Xe 24 phòng Limousine",
    first: ["0", null, "", "", "", "", "", "", "", "", "", "", "", ""],
    second: [null, null, "", "", "", "", "", "", "", "", "", "", "", ""],
    nos: 24,
    spr: 2,
  },
  15: {
    name: "Xe 29 ghế",
    first: [
      "0",
      null,
      null,
      "",
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      null,
      null,
      null,
      "",
      "",
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 29,
    spr: 5,
  },
  16: {
    name: "Xe 32 giường luxury có WC",
    first: [
      "0",
      null,
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "WC",
    ],
    second: [
      null,
      null,
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "WC",
    ],
    nos: 32,
    spr: 3,
  },
  17: {
    name: "Xe 33 ghế",
    first: [
      "0",
      null,
      null,
      "",
      "",
      null,
      null,
      null,
      null,
      "",
      "",
      "",
      null,
      null,
      null,
      "",
      "",
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 33,
    spr: 5,
  },
  18: {
    name: "Xe 34 giường Luxury",
    first: [
      "0",
      null,
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    second: [
      null,
      null,
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    nos: 34,
    spr: 3,
  },
  19: {
    name: "Xe 36 giường",
    first: [
      "0",
      null,
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    second: [
      null,
      null,
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    nos: 36,
    spr: 3,
  },
  20: {
    name: "Xe 40 giường",
    first: [
      "0",
      null,
      null,
      null,
      null,
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    second: [
      null,
      null,
      null,
      null,
      null,
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    nos: 40,
    spr: 5,
  },
  21: {
    name: "Xe 44 giường",
    first: [
      "0",
      null,
      null,
      null,
      null,
      "",
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    second: [
      null,
      null,
      null,
      null,
      null,
      "",
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      null,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    nos: 44,
    spr: 5,
  },
  22: {
    name: "Xe 45 ghế",
    first: [
      "0",
      null,
      null,
      null,
      null,
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    second: [],
    nos: 45,
    spr: 5,
  },
};
